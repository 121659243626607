import React, { useRef, useState } from 'react';
import { MdOutlineFileUpload, MdDelete, MdClose, MdCheckCircle, MdCheckCircleOutline, MdCheck, MdOutlineCheckCircle } from 'react-icons/md';
import { useAppDispatch } from '../../redux/hooks';
import { addNotification } from '../../redux/slices/notificationSlice';
import { MessageType } from '../../types';
import { MAX_HEIGHT, MAX_WIDTH } from '../../types/IRoom';

interface IProps {
    title: string;
    roomIndex: number;
    onFileChange: (roomIndex: number, file: File | null) => void;
    initialFileName?: string; // Valore iniziale per sincronizzazione visiva
    isUploaded?: boolean; // Flag per sincronizzazione visiva
}

const RoomUploader = ({ title, roomIndex, onFileChange, initialFileName, isUploaded }: IProps) => {
    const dispatch = useAppDispatch();

    const fileInput = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState<string | null>(initialFileName ?? null);
    const [dragCounter, setDragCounter] = useState(0);

    const validateFile = (file: File): boolean => {
        if (file.type !== 'application/json') {
            dispatch(addNotification({ message: "Solo file JSON sono accettati", type: MessageType.WARNING }));
            return false;
        }
        return true;
    };

    const validateFileContent = async (file: File): Promise<boolean> => {
        try {
            const text = await file.text(); // Legge il contenuto del file come testo
            const data = JSON.parse(text); // Analizza il contenuto come JSON

            // Verifica delle proprietà richieste
            if (!data.width || typeof data.width !== 'number' || data.width <= 0 || data.width > MAX_WIDTH) {
                dispatch(addNotification({ message: "Larghezza stanza mancante o non valida", type: MessageType.WARNING }));
                return false;
            }
            if (!data.height || typeof data.height !== 'number' || data.height <= 0 || data.height > MAX_HEIGHT) {
                dispatch(addNotification({ message: "Altezza stanza mancante o non valida", type: MessageType.WARNING }));
                return false;
            }

            return true;
        } catch (error) {
            dispatch(addNotification({ message: "Errore nel leggere il contenuto del file JSON", type: MessageType.ERROR }));
            return false;
        }
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let isFileValid = false;

        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            if (validateFile(file)) {
                const isValid = await validateFileContent(file); // Valida il contenuto del file
                if (isValid) {
                    isFileValid = true;
                    setFileName(file.name);
                    onFileChange(roomIndex, file);
                }
            }
        }

        if (!isFileValid) {
            e.target.value = '';
            setFileName(null);
            onFileChange(roomIndex, null);
        }
    };

    const handleFileDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragCounter(0);

        if (e.dataTransfer.files.length > 1) {
            dispatch(addNotification({ message: "Puoi caricare un solo file per stanza", type: MessageType.WARNING }));
            return;
        }

        const file = e.dataTransfer.files[0];
        if (validateFile(file)) {
            const isValid = await validateFileContent(file); // Valida il contenuto del file
            if (isValid) {
                setFileName(file.name);
                onFileChange(roomIndex, file);
                if (fileInput.current) {
                    const dataTransfer = new DataTransfer();
                    dataTransfer.items.add(file);
                    fileInput.current.files = dataTransfer.files; // Link dropped file to hidden input
                }
            } else {
                setFileName(null);
                onFileChange(roomIndex, null);
            }
        }
    };

    const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setFileName(null);
        onFileChange(roomIndex, null);
        if (fileInput.current) {
            fileInput.current.value = ''; // Reset the input file
        }
    };

    return (
        <div
            className={`border rounded-lg p-4 flex flex-col items-center justify-center bg-white cursor-pointer ${dragCounter > 0 ? 'bg-gray-100' : 'hover:bg-gray-100'}`}
            onClick={() => fileInput.current?.click()}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => handleFileDrop(e)}
            onDragEnter={(e) => setDragCounter((prev) => prev + 1)}
            onDragLeave={(e) => setDragCounter((prev) => prev - 1)}
        >
            <span className='text-center font-bold'>{title}</span>
            <div className='flex flex-col items-center gap-2 mt-2 mb-6'>
                {isUploaded ? (
                    <MdCheck size={80} className='text-blue-600' /> // Icona per file già caricato
                ) : fileName ? (
                    <MdCheck size={80} className='text-green-600' />
                ) : (
                    <MdOutlineFileUpload size={80} />
                )}
                <input
                    type='file'
                    ref={fileInput}
                    hidden
                    onChange={handleFileChange}
                    accept="application/json" // Limit input to JSON files
                />
                <span className='text-sm text-gray-600 truncate w-full text-center'>
                    {fileName ?? (isUploaded ? initialFileName : 'Nessun file selezionato')}
                </span>
            </div>
            <button className='btn btn-danger' disabled={!fileName || isUploaded} onClick={handleDeleteClick}>
                <MdDelete size={24} />
            </button>
        </div>
    );
};

export default RoomUploader;
