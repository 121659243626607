import React from 'react';
import PieChart from '../charts/PieChart';

function LevelsTotal() {
    const data = [
        { title: 'Superati', text: '20/80 | 25%', value: 20 },
        { title: 'Non completati', text: '60/80 | 75%', value: 60 },
    ];

    return (
        <section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg'>

            <h3 className='h3'>Totale Livelli</h3>

            <div className='grid grid-cols-3 gap-6 mt-3'>

                <div className='col-span-1'>
                    <div className='flex flex-col gap-2'>
                        {data.map((item, index) => (
                            <div key={index} className='flex flex-col items-center text-center'>
                                <span className='font-bold'>{item.title}</span>
                                <span>{item.text}</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='col-span-2'>
                    <PieChart
                        datasetLabel='Livelli'
                        labels={data.map(item => item.title)}
                        data={data.map(item => item.value)}
                    />
                </div>

            </div>

        </section>
    );
}

export default LevelsTotal;