import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { hidePopup } from '../../redux/slices/actionsPopupSlice';
import { useActionsPopup } from '../../context/ActionsPopupContext';
import { MdDelete, MdEditNote, MdLockReset, MdOutlineScreenShare, MdPersonAdd, MdQueryStats, MdUpload } from 'react-icons/md';

function ActionsPopup() {
    const dispatch = useAppDispatch();
    const { executeCallback } = useActionsPopup();
    const { actionsPopupVisible: visible, position, menu } = useAppSelector((state) => state.actionsPopup);
    const popupRef = useRef<HTMLDivElement | null>(null);

    const iconMap: { [key: string]: JSX.Element } = {
        edit: <MdEditNote size={24} />,
        reset: <MdLockReset size={24} />,
        personAdd: <MdPersonAdd size={24} />,
        upload: <MdUpload size={24} />,
        share: <MdOutlineScreenShare size={24} />,
        stats: <MdQueryStats size={24} />,
        delete: <MdDelete size={24} />,
    };

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            // Chiudi il popup se visibile e si clicca fuori
            if (visible && popupRef.current && !popupRef.current.contains(e.target as Node)) {
                dispatch(hidePopup());
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [visible]);

    if (!visible) return null;
    return (
        <div
            ref={popupRef}
            className={`absolute z-10 bg-white shadow-lg border rounded-md p-2 w-48`}
            style={{
                left: position.anchor.horizontal === 'left' ? position.x - 192 : position.x,
                top: position.anchor.vertical === 'top' ? position.y - ((menu.length - 1) * 40) - 25 : position.y, // Regola l'altezza del popup secondo le necessità
            }}
        >
            <ul>
                {menu.map((item, index) => (
                    <li key={index} onClick={(e) => {
                        e.stopPropagation(); // Impedisce la propagazione del clic
                        executeCallback(item.actionId); // Esegui la callback registrata
                        dispatch(hidePopup());
                    }} className='flex items-center gap-3 p-2 hover:bg-gray-100 cursor-pointer'>
                        {iconMap[item.iconId]} {item.title}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ActionsPopup;
