export default interface IVendor {
    Id: number;
    Name: string;
    Creation: string;
    Timestamp: string;
}

export const exampleVendor: IVendor = {
    Id: 0,
    Name: '',
    Creation: '',
    Timestamp: '',
};