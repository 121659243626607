export enum PageType {
    ALL = 'ALL',
    SINGLE = 'SINGLE',
    CREATE = 'CREATE',
    EDIT = 'EDIT',
}
export type PageTypeForm = PageType.CREATE | PageType.EDIT | PageType.SINGLE;

export enum UserRole {
    Administrator = 'Administrator',
    Manager = 'Manager',
    Employee = 'Employee',
}

export enum UserSex {
    MALE = 'M',
    FEMALE = 'F',
}

export enum GoalLevel {
    Quiz = 'Quiz',
    RaccoltaOggetti = 'Raccolta oggetti',
    RaggiungiStanza = 'Raggiungi stanza',
    StanzaOggetti = 'Stanza con oggetti',
    Deposito = 'Deposito',
}

export enum StatusEnum {
    DRAFT = 'Bozza',
    PUBLISHED = 'Pubblicato',
}

export enum MessageType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
}

export enum PositionType {
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left',
    RIGHT = 'right',
}
export type HorizontalPosition = PositionType.LEFT | PositionType.RIGHT;
export type VerticalPosition = PositionType.TOP | PositionType.BOTTOM;

export enum PasswordType {
    OLD = 'old',
    NEW = 'new',
    CONFIRM = 'confirm',
}

export enum StatusType {
    DRAFT = 'Bozza',
    PUBLISHED = 'Pubblicato',
}
