import { AppDispatch } from '../redux/store'; // Tipo di dispatch
import { addNotification } from '../redux/slices/notificationSlice';
import { showPopup, hidePopup } from '../redux/slices/popupSlice';
import { deleteLevelGroup, publishLevelGroup } from '../redux/slices/levelGroupSlice';
import { MessageType, StatusEnum } from '../types';
import ILevelGroup from '../types/ILevelGroup';

// Funzione per gestire la pubblicazione di un paccheto di livelli
export const handlePublishLevelGroup = async (
    dispatch: AppDispatch,
    levelGroup: ILevelGroup | null,
    registerCallback: (id: string, callback: () => void) => void,
) => {
    if (!levelGroup) {
        dispatch(addNotification({ message: 'Pacchetto di livelli non valido', type: MessageType.ERROR, tag: 'publishLevelGroup' }));
        return;
    }

    if (levelGroup.Status === StatusEnum.PUBLISHED) {
        dispatch(addNotification({ message: 'Il pacchetto è già stato pubblicato', type: MessageType.WARNING, tag: 'publishLevelGroup' }));
        return;
    } else if (levelGroup.Levels.length === 0) {
        dispatch(addNotification({ message: 'Nessun livello assegnato al pacchetto', type: MessageType.WARNING, tag: 'publishLevelGroup' }));
        return;
    } else if (levelGroup.Employees.length === 0) {
        dispatch(addNotification({ message: 'Nessun dipendente assegnato al pacchetto', type: MessageType.WARNING, tag: 'publishLevelGroup' }));
        return;
    }

    const callbackId = 'confirmPublishLevel';
    registerCallback(callbackId, async () => {
        dispatch(publishLevelGroup(levelGroup.Id))
            .then((result) => {
                if (publishLevelGroup.fulfilled.match(result)) {
                    dispatch(addNotification({ message: 'Pacchetto di livelli pubblicato con successo', type: MessageType.SUCCESS, tag: 'publishLevelGroup' }));
                    dispatch(hidePopup());
                }
            })
            .catch((error) => {
                dispatch(addNotification({ message: error || 'Errore durante la richiesta di pubblicazione', type: MessageType.ERROR, tag: 'publishLevel' }));
            });
    });

    dispatch(
        showPopup({
            type: MessageType.INFO,
            message: 'Sei sicuro di voler pubblicare il pacchetto di livelli? Verrano pubblicati anche i livelli assegnati',
            onConfirmId: callbackId,
        })
    );
};

// Funzione per gestire la cancellazione di un paccheto di livelli
export const handleDeleteLevelGroup = async (
    dispatch: AppDispatch,
    levelGroup: ILevelGroup | null,
    registerCallback: (id: string, callback: () => void) => void,
    navigate?: (path: string) => void,
) => {
    if (!levelGroup) {
        dispatch(addNotification({ message: 'Pacchetto di livelli non valido', type: MessageType.ERROR, tag: 'deleteLevelGroup' }));
        return;
    }

    const callbackId = 'confirmDeleteLevelGroup';
    registerCallback(callbackId, async () => {
        dispatch(deleteLevelGroup(levelGroup.Id))
            .then((result) => {
                if (deleteLevelGroup.fulfilled.match(result)) {
                    dispatch(addNotification({ message: 'Pacchetto di livelli eliminato con successo', type: MessageType.SUCCESS, tag: 'deleteLevelGroup' }));
                    dispatch(hidePopup());
                    navigate?.('/levels/groups');
                }
            })
            .catch((error) => {
                dispatch(addNotification({ message: error || 'Errore durante la richiesta di eliminazione', type: MessageType.ERROR, tag: 'deleteLevelGroup' }));
            });
    });

    dispatch(
        showPopup({
            type: MessageType.ERROR,
            message: 'Sei sicuro di voler eliminare il pacchetto di livelli? L\'operazione è irreversibile',
            onConfirmId: callbackId,
        })
    );
};
