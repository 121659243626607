import IFile, { exampleFile } from "./IFile";
import ILevel, { exampleLevel } from "./ILevel";

export const MAX_WIDTH = 100;
export const MAX_HEIGHT = 100;

export default interface IRoom {
    Id: number;
    Id_Level: number;
    Id_File: number;
    Creation: string;
    Timestamp: string;

    //* NotMapped
    Level: ILevel;
    File: IFile;
}

export const exampleRoom: IRoom = {
    Id: 0,
    Id_Level: 0,
    Id_File: 0,
    Creation: '',
    Timestamp: '',

    Level: exampleLevel,
    File: exampleFile,
};