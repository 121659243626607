import React from 'react';
import PaginationControls from '../utils/PaginationControls';
import ProgressBar from '../utils/ProgressBar';
import Tooltip from '../utils/Tooltip';
import { PositionType } from '../../types';

function LatestAccesses() {
    return (
        <section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg'>

            <div className='w-full flex items-center justify-between'>
                <h3 className='h3'>Ultimi Accessi</h3>
                <div className='flex items-center gap-3'>
                    <span>Mostra</span>
                    <select className='border border-gray-300 rounded-md px-1' value={10} onChange={() => { }}>
                        <option value='5'>5</option>
                        <option value='10'>10</option>
                        <option value='15'>15</option>
                        <option value='20'>20</option>
                    </select>
                </div>
            </div>

            <table className='mt-3'>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Avanzamento</th>
                        <th>Ultimo accesso</th>
                    </tr>
                </thead>
                <tbody>
                    {[
                        { name: 'Mario Rossi', progress: '50%', LastAccess: '10/10/2021 | 10:38' },
                        { name: 'Luigi Bianchi', progress: '30%', LastAccess: '10/10/2021 | 10:38' },
                        { name: 'Giuseppe Verdi', progress: '70%', LastAccess: '10/10/2021 | 10:38' },
                        { name: 'Giovanni Neri', progress: '90%', LastAccess: '10/10/2021 | 10:38' },
                        { name: 'Antonio Gialli', progress: '40%', LastAccess: '10/10/2021 | 10:38' },
                    ].map((user, index) => (
                        <tr key={index}>
                            <td>{user.name}</td>
                            <td>
                                <Tooltip text={user.progress} position={PositionType.TOP}>
                                    <div>
                                        <ProgressBar progress={user.progress} />
                                    </div>
                                </Tooltip>
                            </td>
                            <td>{user.LastAccess}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <PaginationControls page={1} totalPages={10} onPageChange={() => { }} />

        </section>
    );
}

export default LatestAccesses;