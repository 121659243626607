import React, { createContext, useContext, useState } from 'react';

interface ActionsPopupContextValue {
    registerCallback: (id: string, callback: () => void) => void;
    executeCallback: (id: string) => void;
}

const ActionsPopupContext = createContext<ActionsPopupContextValue | undefined>(undefined);

export function ActionsPopupProvider({ children }: { children: React.ReactNode }) {
    const [callbacks, setCallbacks] = useState<{ [key: string]: () => void }>({});

    const registerCallback = (id: string, callback: () => void) => {
        setCallbacks((prev) => ({ ...prev, [id]: callback }));
    };

    const executeCallback = (id: string) => {
        if (callbacks[id]) {
            callbacks[id]();
        }
    };

    return (
        <ActionsPopupContext.Provider value={{ registerCallback, executeCallback }}>
            {children}
        </ActionsPopupContext.Provider>
    );
};

export const useActionsPopup = () => {
    const context = useContext(ActionsPopupContext);
    if (!context) {
        throw new Error('useActionsPopup must be used within an ActionsPopupProvider');
    }
    return context;
};
