import React from 'react';
import LoadingIcon from '../../images/loading.svg';

interface IProps {
    width?: string;
    height?: string;
}

function Loading({ width = '100%', height = '60vh' }: IProps) {
    return (
        <div className='flex items-center justify-center'
            style={{
                width,
                minHeight: height,
            }}
        >

            <img src={LoadingIcon} alt='Loading...' className='w-40' />

        </div>
    );
}

export default Loading;
