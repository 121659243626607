import React, { useEffect, useState } from 'react';
import { MdAdd, MdOutlineClose } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import PaginationControls from '../utils/PaginationControls';
import { CiMenuKebab } from 'react-icons/ci';
import SearchBar from '../utils/SearchBar';
import calculatePopupPosition from '../../utils/calculatePopupPosition';
import { addNotification } from '../../redux/slices/notificationSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { MessageType, StatusEnum } from '../../types';
import { useActionsPopup } from '../../context/ActionsPopupContext';
import { hidePopup as hideActionsPopup, showPopup as showActionsPopup } from '../../redux/slices/actionsPopupSlice';
import { fetchLevelGroups } from '../../redux/slices/levelGroupSlice';
import Loading from '../utils/Loading';
import ILevelGroup from '../../types/ILevelGroup';
import { usePopup } from '../../context/PopupContext';
import { handleDeleteLevelGroup, handlePublishLevelGroup } from '../../utils/levelGroup-actions';

function LevelGroupsTable() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { registerCallback: registerActionsPopupCallback } = useActionsPopup();
    const { registerCallback: registerConfirmationPopupCallback } = usePopup();
    const { actionsPopupVisible, activeRowIndex } = useAppSelector((state) => state.actionsPopup);
    const { levelGroups, pages, loadingLevelGroups, errorLevelGroups } = useAppSelector((state) => state.levelGroup);

    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);

    useEffect(() => {
        dispatch(fetchLevelGroups({ page, limit: 15, searchTerm }));
    }, [dispatch, page, searchTerm]);

    useEffect(() => {
        if (errorLevelGroups) {
            dispatch(addNotification({ message: errorLevelGroups, type: MessageType.ERROR, tag: 'errorLevelGroups' }));
        }
    }, [errorLevelGroups]);

    const handleIconClick = (e: React.MouseEvent, index: number, levelGroup: ILevelGroup) => {
        e.stopPropagation();

        if (actionsPopupVisible && activeRowIndex === index) {
            // Se il popup è già visibile per questa riga, chiudilo
            dispatch(hideActionsPopup());
            return;
        }

        registerActionsPopupCallback('edit', () => {
            if (levelGroup && levelGroup.Id) {
                navigate(`./${levelGroup.Id}/edit`);
            } else {
                dispatch(addNotification({ message: "Impossibile modificare il pacchetto di livelli", type: MessageType.ERROR }));
            }
        });
        registerActionsPopupCallback('publish', () => handlePublishLevelGroup(dispatch, levelGroup, registerConfirmationPopupCallback));
        registerActionsPopupCallback('stats', () => dispatch(addNotification({ message: 'statistiche', type: MessageType.INFO })));
        registerActionsPopupCallback('delete', () => handleDeleteLevelGroup(dispatch, levelGroup, registerConfirmationPopupCallback));

        // Use the utility function to calculate the position
        const position = calculatePopupPosition(e);

        dispatch(
            showActionsPopup({
                position,
                activeRowIndex: index,
                menu: [
                    { title: 'Modifica', actionId: 'edit', iconId: 'edit', },
                    { title: 'Pubblica', actionId: 'publish', iconId: 'share', },
                    { title: 'Statistiche', actionId: 'stats', iconId: 'stats', },
                    { title: 'Elimina', actionId: 'delete', iconId: 'delete', },
                ],
            })
        );
    };

    return (
        <section>

            <div className='grid grid-cols-2 items-center mb-6'>
                <div className='col-span-1'>
                    <h1 className='h1'>Pacchetti di Livelli</h1>
                </div>
                <div className='col-span-1 flex items-center gap-5'>
                    <SearchBar value={searchTerm} onChange={setSearchTerm} />
                    <Link className='btn' to={'/levels/groups/create'}>Aggiungi <MdAdd size={18} /></Link>
                </div>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Stato</th>
                        <th>Livelli</th>
                        <th>Dipendenti</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {loadingLevelGroups && (
                        <tr>
                            <td colSpan={5}>
                                <Loading height='400px' />
                            </td>
                        </tr>
                    )}
                    {!loadingLevelGroups && !errorLevelGroups && levelGroups.map((levelGroup, index) => (
                        <tr key={index}
                            className='hover:bg-gray-200 cursor-pointer'
                            onClick={() => { navigate(`${levelGroup.Id}`) }}
                        >
                            <td>{levelGroup.Name}</td>
                            <td>{levelGroup.Status} {levelGroup.Status === StatusEnum.PUBLISHED ? '✅' : '🕒'}</td>
                            <td>{levelGroup.Levels.length} {levelGroup.Levels.length > 0 ? '✅' : '❌'}</td>
                            <td>{levelGroup.Employees.length} {levelGroup.Employees.length > 0 ? '✅' : '❌'}</td>
                            <td>
                                <button onClick={(e) => handleIconClick(e, index, levelGroup)}>
                                    {actionsPopupVisible && activeRowIndex === index ? (
                                        <MdOutlineClose size={28} /> // Mostra l'icona "X" se il popup è visibile per questa riga
                                    ) : (
                                        <CiMenuKebab size={28} /> // Mostra i tre puntini altrimenti
                                    )}
                                </button>
                            </td>
                        </tr>
                    ))}
                    {!loadingLevelGroups && !errorLevelGroups && levelGroups.length === 0 && (
                        <tr>
                            <td colSpan={5} className='italic text-gray-600'>Nessun risultato trovato...</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <PaginationControls page={page} totalPages={pages} onPageChange={setPage} />

        </section>
    );
}

export default LevelGroupsTable;
