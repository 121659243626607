import IVendor, { exampleVendor } from "./IVendor";

export default interface ICompany {
    Id: number;
    Id_Vendor: number;
    Name: string;
    Headquarter: string;
    VAT: string;
    Creation: string;
    Timestamp: string;

    //* NotMapped
    Vendor: IVendor;
}

export const exampleCompany: ICompany = {
    Id: 0,
    Id_Vendor: 0,
    Name: '',
    Headquarter: '',
    VAT: '',
    Creation: '',
    Timestamp: '',

    Vendor: exampleVendor,
};