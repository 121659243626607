import React, { useState } from 'react';
import { PositionType } from '../../types';

interface IProps {
    text: string;
    children: React.ReactNode;
    position?: PositionType;
}

function Tooltip({ text, children, position = PositionType.TOP }: IProps) {
    const [isVisible, setIsVisible] = useState(false);

    const showTooltip = () => setIsVisible(true);
    const hideTooltip = () => setIsVisible(false);

    // Determine positioning classes
    const tooltipPositionClasses = () => {
        return position === PositionType.TOP
            ? 'bottom-full mb-2 left-1/2 -translate-x-1/2'
            : position === PositionType.BOTTOM
                ? 'top-full mt-2 left-1/2 -translate-x-1/2'
                : position === PositionType.LEFT
                    ? 'right-full mr-2 top-1/2 -translate-y-1/2'
                    : position === PositionType.RIGHT
                        ? 'left-full ml-2 top-1/2 -translate-y-1/2'
                        : '';
    };

    // Determine arrow position and rotation
    const arrowPositionClasses = () => {
        const arrowBaseClass = 'absolute w-2 h-2 bg-gray-800 rotate-45';
        if (position === PositionType.TOP) {
            return `${arrowBaseClass} top-full -translate-y-1/2 left-1/2 -translate-x-1/2`;
        } else if (position === PositionType.BOTTOM) {
            return `${arrowBaseClass} bottom-full translate-y-1/2 left-1/2 -translate-x-1/2`;
        } else if (position === PositionType.LEFT) {
            return `${arrowBaseClass} left-full -translate-x-1/2 top-1/2 -translate-y-1/2`;
        } else if (position === PositionType.RIGHT) {
            return `${arrowBaseClass} right-full translate-x-1/2 top-1/2 -translate-y-1/2`;
        }
        return `${arrowBaseClass}`; // Default
    };

    return (
        <div
            className="w-full relative inline-block"
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
        >
            {children}
            {isVisible && (
                <div
                    className={`absolute ${tooltipPositionClasses()} text-center p-2 bg-gray-800 text-white text-sm rounded shadow-lg z-10`}
                    style={{ whiteSpace: 'wrap' }}
                >
                    {text}
                    <div className={arrowPositionClasses()}></div>
                </div>
            )}
        </div>
    );
};

export default Tooltip;
