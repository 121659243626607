import { GoalLevel, StatusEnum } from ".";
import IEmployee from "./IEmployee";
import ILevelGroup from "./ILevelGroup";
import IRoom from "./IRoom";

export const MIN_ROOMS = 1;
export const MAX_ROOMS = 20;

export default interface ILevel {
    Id: number;
    Id_Company: number;
    Name: string;
    Goal: GoalLevel;
    Status: StatusEnum;
    Link: string;
    Creation: string;
    Timestamp: string;

    //* NotMapped
    RoomsNumber: number;
    Rooms: IRoom[];
    LevelGroups: ILevelGroup[];
    Employees: IEmployee[];
}

export const exampleLevel: ILevel = {
    Id: 0,
    Id_Company: 0,
    Name: '',
    Goal: GoalLevel.Quiz,
    Status: StatusEnum.DRAFT,
    Link: '',
    Creation: '',
    Timestamp: '',

    RoomsNumber: 0,
    Rooms: [],
    LevelGroups: [],
    Employees: [],
};