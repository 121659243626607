import React from 'react';
import PieChart from '../charts/PieChart';

function CompletedLevels() {
    const data = [
        { title: 'Quiz', text: 'X / X', value: 5 },
        { title: 'Raccolta Oggetti', text: '20 / 40', value: 50 },
        { title: 'Raggiungi Stanza', text: '30 / 40', value: 75 },
        { title: 'Stanza con Oggetti', text: '12 / 20', value: 60 },
        { title: 'Deposito Oggetti', text: '5 / 20', value: 25 },
    ];

    return (
        <section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg'>

            <h3 className='h3'>Livelli Superati</h3>

            <div className='grid grid-cols-3 gap-6 mt-3'>

                <div className='col-span-1'>
                    <div className='flex flex-col gap-2'>
                        {data.map((item, index) => (
                            <div key={index} className='flex flex-col items-center text-center'>
                                <span className='font-bold'>{item.title}</span>
                                <span>{item.text}</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='col-span-2'>
                    <PieChart
                        datasetLabel='Livelli'
                        labels={data.map(item => item.title)}
                        data={data.map(item => item.value)}
                    />
                </div>

            </div>

        </section>
    );
}

export default CompletedLevels;