import React, { useEffect, useState } from 'react';
import { MdDelete, MdEditDocument, MdSave } from 'react-icons/md';
import IEmployee, { exampleEmployee } from '../../types/IEmployee';
import { isValidCF, isValidEmail, isValidPhone } from '../../utils/functions';
import { createEmployee, fetchEmployeeById, updateEmployee } from '../../redux/slices/employeeSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../utils/Loading';
import { MessageType, PageType, PageTypeForm } from '../../types';
import { addNotification } from '../../redux/slices/notificationSlice';
import { Link } from 'react-router-dom';
import { handleDeleteEmployee } from '../../utils/employee-actions';
import { usePopup } from '../../context/PopupContext';

interface IProps {
    type: PageTypeForm;
}

function EmployeeForm({ type }: IProps) {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { registerCallback } = usePopup();
    const { loadingEmployees, errorEmployees } = useAppSelector((state) => state.employee);

    const [formData, setFormData] = useState<IEmployee>(exampleEmployee);
    const [phoneExtension, setPhoneExtension] = useState('+39');

    useEffect(() => {
        if (id) {
            dispatch(fetchEmployeeById(Number(id)))
                .then((result) => {
                    if (fetchEmployeeById.fulfilled.match(result)) {
                        setFormData(result.payload);
                    }
                });
        } else if (type === PageType.SINGLE || type === PageType.EDIT) {
            navigate('/employees');
        }
    }, [id]);

    useEffect(() => {
        if (errorEmployees) {
            dispatch(addNotification({ message: errorEmployees, type: MessageType.ERROR, tag: 'errorEmployees' }));
        }
    }, [errorEmployees]);

    const handlePhoneExtensionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setPhoneExtension(e.target.value);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        let formattedValue = value;

        // Dividi il nome per individuare la struttura annidata, se presente
        const nameParts = name.split('.');

        setFormData((prevData) => {
            // Clona l'oggetto corrente di formData
            const updatedData = { ...prevData };

            // Naviga attraverso l'oggetto fino all'ultimo campo
            let currentLevel: any = updatedData;
            for (let i = 0; i < nameParts.length - 1; i++) {
                currentLevel[nameParts[i]] = { ...currentLevel[nameParts[i]] }; // Clona ogni livello per evitare la mutazione dello stato originale
                currentLevel = currentLevel[nameParts[i]];
            }

            const currentField = nameParts[nameParts.length - 1];

            if (currentField === 'Name' || currentField === 'Surname') {
                formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
            }
            else if (currentField === 'CF') {
                formattedValue = value.toUpperCase();
            }
            else if (currentField === 'Phone') {
                formattedValue = value.replace(/\D/g, ''); // Rimuove caratteri non numerici
            }

            // Aggiorna il valore del campo specifico
            currentLevel[currentField] = formattedValue;

            return updatedData;
        });
    };

    const handleSave = () => {
        const fullPhone = `${phoneExtension}${formData.UserCompany.Phone}`;

        if (!formData.UserCompany.Name || !formData.UserCompany.Surname || !formData.UserCompany.CF || !formData.UserCompany.Phone || !formData.Badge || !formData.Department) {
            dispatch(addNotification({ message: 'Compilare tutti i campi', type: MessageType.WARNING }));
            return;
        }
        if (!isValidCF(formData.UserCompany.CF)) {
            dispatch(addNotification({ message: 'Codice Fiscale non valido', type: MessageType.WARNING }));
            return;
        }
        if (!isValidEmail(formData.UserCompany.Account.Email)) {
            dispatch(addNotification({ message: 'Email non valida', type: MessageType.WARNING }));
            return;
        }
        if (!isValidPhone(fullPhone)) {
            dispatch(addNotification({ message: 'Telefono non valido', type: MessageType.WARNING }));
            return;
        }

        setFormData((prevData) => ({
            ...prevData,
            UserCompany: {
                ...prevData.UserCompany,
                Phone: fullPhone,
            },
        }));

        if (type === PageType.EDIT && id) {
            dispatch(updateEmployee({ id: Number(id), newEmployee: formData }))
                .then((result) => {
                    if (updateEmployee.fulfilled.match(result)) {
                        dispatch(addNotification({ message: 'Dipendente aggiornato con successo', type: MessageType.SUCCESS }));
                        navigate('/employees');
                    } else if (updateEmployee.rejected.match(result)) {
                        const number = fullPhone.replace(phoneExtension, '');
                        resetPhoneNumber(number);
                    }
                })
                .catch(() => dispatch(addNotification({ message: 'Errore durante l\'aggiornamento', type: MessageType.ERROR })));
        } else if (type === PageType.CREATE) {
            dispatch(createEmployee(formData))
                .then((result) => {
                    if (createEmployee.fulfilled.match(result)) {
                        dispatch(addNotification({ message: 'Dipendente creato con successo', type: MessageType.SUCCESS }));
                        navigate('/employees');
                    } else if (createEmployee.rejected.match(result)) {
                        const number = fullPhone.replace(phoneExtension, '');
                        resetPhoneNumber(number);
                    }
                })
                .catch(() => dispatch(addNotification({ message: 'Errore durante la creazione', type: MessageType.ERROR })));
        } else {
            dispatch(addNotification({ message: 'Azione non riconosciuta', type: MessageType.WARNING }));
        }
    };

    const resetPhoneNumber = (number: string) => {
        setFormData((prevData) => ({
            ...prevData,
            UserCompany: {
                ...prevData.UserCompany,
                Phone: number,
            },
        }));
    };

    return (
        <section>

            <h1 className='h1'>
                {type === PageType.SINGLE ?
                    'Visualizzazione ' :
                    type === PageType.CREATE ?
                        'Creazione ' :
                        type === PageType.EDIT ?
                            'Modifica ' :
                            ''
                }
                Dipendente
            </h1>

            <div className='w-full flex flex-col gap-4 p-6 bg-white rounded-2xl shadow-lg my-6'>

                <h3 className='h3'>Anagrafica</h3>

                <div className='form-element !grid !grid-cols-7 gap-4'>
                    <label htmlFor='nome'>Nome</label>
                    <input type='text' id='nome' name='UserCompany.Name' className='col-span-6' disabled={type === PageType.SINGLE} value={formData.UserCompany.Name} onChange={handleChange} />
                </div>

                <div className='form-element !grid !grid-cols-7 gap-4'>
                    <label htmlFor='cognome'>Cognome</label>
                    <input type='text' id='cognome' name='UserCompany.Surname' className='col-span-6' disabled={type === PageType.SINGLE} value={formData.UserCompany.Surname} onChange={handleChange} />
                </div>

                <div className='form-element !grid !grid-cols-7 gap-4'>
                    <label htmlFor='cf'>Codice Fiscale</label>
                    <input type='text' id='cf' name='UserCompany.CF' className='col-span-6' maxLength={16} disabled={type === PageType.SINGLE} value={formData.UserCompany.CF} onChange={handleChange} />
                </div>

                <div className='form-element !grid !grid-cols-7 gap-4'>
                    <label htmlFor='phone'>Telefono</label>
                    <div className='col-span-6 flex items-center gap-3'>
                        <select className='max-w-20' disabled={type === PageType.SINGLE} value={phoneExtension} onChange={handlePhoneExtensionChange}>
                            <option value='+32'>+32</option>
                            <option value='+33'>+33</option>
                            <option value='+34'>+34</option>
                            <option value='+39'>+39</option>
                            <option value='+41'>+41</option>
                            <option value='+44'>+44</option>
                            <option value='+49'>+49</option>
                        </select>
                        <input type='tel' id='phone' name='UserCompany.Phone' disabled={type === PageType.SINGLE} value={formData.UserCompany.Phone} onChange={handleChange} />
                    </div>
                </div>

            </div>

            <div className='w-full flex flex-col gap-4 p-6 bg-white rounded-2xl shadow-lg my-6'>

                <h3 className='h3'>Informazioni aziendali</h3>

                <div className='form-element !grid !grid-cols-7 gap-4'>
                    <label htmlFor='email'>Email</label>
                    <input type='email' id='email' name='UserCompany.Account.Email' className='col-span-6' disabled={type !== PageType.CREATE} value={formData.UserCompany.Account.Email} onChange={handleChange} />
                </div>

                <div className='form-element !grid !grid-cols-7 gap-4'>
                    <label htmlFor='badge'>Matricola</label>
                    <input type='text' id='badge' name='Badge' className='col-span-6' disabled={type === PageType.SINGLE} value={formData.Badge} onChange={handleChange} />
                </div>

                <div className='form-element !grid !grid-cols-7 gap-4'>
                    <label htmlFor='department'>Reparto</label>
                    <input type='text' id='department' name='Department' className='col-span-6' disabled={type === PageType.SINGLE} value={formData.Department} onChange={handleChange} />
                </div>

                <div className='form-element !grid !grid-cols-7 gap-4'>
                    <label htmlFor='notes'>Note</label>
                    <input type='text' id='notes' name='Notes' className='col-span-6' disabled={type === PageType.SINGLE} value={formData.Notes ?? ''} onChange={handleChange} />
                </div>

            </div>

            <div className='w-full flex items-center'>

                {type === PageType.SINGLE ? (
                    <div className='w-full flex items-center justify-between gap-10'>
                        <Link to={'./edit'} className='btn btn-lg'>Modifica <MdEditDocument size={20} /></Link>
                        <button className='btn btn-lg btn-danger' onClick={() => handleDeleteEmployee(dispatch, formData, registerCallback, navigate)}>
                            Elimina <MdDelete size={20} />
                        </button>
                    </div>
                ) : (
                    <button className='btn btn-lg' onClick={handleSave}>Salva <MdSave size={20} /></button>
                )}

                {loadingEmployees && <Loading width='40px' height='40px' />}

            </div>

        </section>
    );
}

export default EmployeeForm;