import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MdDelete, MdEdit, MdOpenInBrowser, MdOutlineCopyAll, MdOutlineScreenShare } from 'react-icons/md';
import { addNotification } from '../../redux/slices/notificationSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { MessageType, StatusEnum } from '../../types';
import { fetchLevelById, } from '../../redux/slices/levelSlice';
import Loading from '../utils/Loading';
import { usePopup } from '../../context/PopupContext';
import { handleDeleteLevel, handlePublishLevel } from '../../utils/level-actions';
import { copyLinkToClipboard } from '../../utils/functions';

function SingleLevel() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { registerCallback } = usePopup();
    const { selectedLevel, loadingLevels, errorLevels } = useAppSelector((state) => state.level);

    useEffect(() => {
        if (id) {
            dispatch(fetchLevelById(Number(id)));
        } else {
            navigate('/levels/groups');
        }
    }, [id]);

    useEffect(() => {
        if (errorLevels) {
            dispatch(addNotification({ message: errorLevels, type: MessageType.ERROR, tag: 'errorLevels' }));
        }
    }, [errorLevels]);

    return (
        <section>

            <h1 className='h1'>Livello</h1>

            <div className='grid grid-cols-3 gap-6 mt-6'>

                <div className='col-span-2'>

                    <div className='w-full p-6 bg-white rounded-2xl shadow-lg'>

                        <h3 className='h3'>Pacchetti di Livelli assegnati</h3>

                        <table className='mt-3'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Pacchetto</th>
                                    <th>Descrizione</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadingLevels && (
                                    <tr>
                                        <td colSpan={2}>
                                            <Loading height='400px' />
                                        </td>
                                    </tr>
                                )}
                                {!loadingLevels && !errorLevels && selectedLevel?.LevelGroups.map((levelGroup, index) => (
                                    <tr key={index}
                                        className='hover:bg-gray-200 cursor-pointer'
                                        onClick={() => navigate(`/levels/groups/${levelGroup.Id}`)}>
                                        <td>{levelGroup.Status === StatusEnum.PUBLISHED ? '✅' : '🕒'}</td>
                                        <td>{levelGroup.Name}</td>
                                        <td>{levelGroup.Description?.substring(0, 35).concat('...') ?? 'N/A'}</td>
                                    </tr>
                                ))}
                                {!loadingLevels && !errorLevels && (!selectedLevel || selectedLevel?.LevelGroups.length === 0) && (
                                    <tr>
                                        <td colSpan={2} className='italic text-gray-600'>Nessun pacchetto di livelli assegnato</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>

                    <div className='w-full p-6 bg-white rounded-2xl shadow-lg mt-6'>

                        <h3 className='h3'>Dipendenti assegnati</h3>

                        <table className='mt-3'>
                            <thead>
                                <tr>
                                    <th>Cognome</th>
                                    <th>Nome</th>
                                    <th>Reparto</th>
                                    <th>Matricola</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadingLevels && (
                                    <tr>
                                        <td colSpan={4}>
                                            <Loading height='400px' />
                                        </td>
                                    </tr>
                                )}
                                {!loadingLevels && !errorLevels && selectedLevel?.Employees.map((employee, index) => (
                                    <tr key={index}
                                        className='hover:bg-gray-200 cursor-pointer'
                                        onClick={() => navigate(`/employees/${employee.Id}`)}>
                                        <td>{employee.UserCompany.Surname}</td>
                                        <td>{employee.UserCompany.Name}</td>
                                        <td>{employee.Department}</td>
                                        <td>{employee.Badge}</td>
                                    </tr>
                                ))}
                                {!loadingLevels && !errorLevels && (!selectedLevel || selectedLevel?.Employees.length === 0) && (
                                    <tr>
                                        <td colSpan={4} className='italic text-gray-600'>Nessun dipendente assegnato</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>

                </div>

                <div className='col-span-1 flex flex-col gap-6'>

                    <div className='w-full p-6 bg-white rounded-2xl shadow-lg'>

                        {loadingLevels ? (
                            <Loading height='400px' />
                        ) : (
                            <div className='flex flex-col items-center justify-center gap-4'>

                                <div className='flex items-center gap-3'>
                                    <h5 className='h5'>Livello:</h5>
                                    <span>{selectedLevel?.Name}</span>
                                </div>

                                <div className='flex items-center gap-3'>
                                    <h5 className='h5'>Obiettivo:</h5>
                                    <span>{selectedLevel?.Goal}</span>
                                </div>

                                <div className='flex items-center gap-3'>
                                    <h5 className='h5'>Numero di Stanze:</h5>
                                    <span>{selectedLevel?.RoomsNumber} {selectedLevel?.RoomsNumber ?? 0 > 0 ? '✅' : '❌'}</span>
                                </div>

                                <div className='flex items-center gap-3'>
                                    <h5 className='h5'>Stato:</h5>
                                    <span>{selectedLevel?.Status} {selectedLevel?.Status === StatusEnum.PUBLISHED ? '✅' : '🕒'}</span>
                                </div>

                                <div className='flex items-center gap-3'>
                                    <h5 className='h5'>Link test:</h5>
                                    <div className='flex items-center justify-center gap-3'>
                                        <Link to={selectedLevel?.Link ?? '#'} target='_blank'>
                                            <MdOpenInBrowser size={28} />
                                        </Link>
                                        <button onClick={() => copyLinkToClipboard(dispatch, selectedLevel?.Link ?? '#')}>
                                            <MdOutlineCopyAll size={28} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>

                    <Link to={'./edit'} className='w-full btn btn-lg'>Modifica <MdEdit size={20} /></Link>
                    {selectedLevel?.Status !== StatusEnum.PUBLISHED && (
                        <button className='w-full btn btn-lg' onClick={() => handlePublishLevel(dispatch, selectedLevel, registerCallback)}>
                            Pubblica <MdOutlineScreenShare size={20} />
                        </button>
                    )}
                    <button className='w-full btn btn-lg btn-danger' onClick={() => handleDeleteLevel(dispatch, selectedLevel, registerCallback, navigate)}>
                        Elimina <MdDelete size={20} />
                    </button>

                </div>

            </div>

        </section>
    );
}

export default SingleLevel;
