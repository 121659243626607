import React, { useEffect, useState } from 'react';
import Loading from '../utils/Loading';
import PaginationControls from '../utils/PaginationControls';
import ILevel from '../../types/ILevel';
import SearchBar from '../utils/SearchBar';
import { addNotification } from '../../redux/slices/notificationSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { MessageType } from '../../types';
import { fetchLevels } from '../../redux/slices/levelSlice';

interface IProps {
    rowsLimit?: number;
    selectedLevels: ILevel[];
    setSelectedLevels: React.Dispatch<React.SetStateAction<ILevel[]>>;
}

function LevelsSelectionTable({ rowsLimit = 15, selectedLevels, setSelectedLevels }: IProps) {
    const dispatch = useAppDispatch();
    const { levels, pages, loadingLevels, errorLevels } = useAppSelector((state) => state.level);

    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStartIndex, setDragStartIndex] = useState<number | null>(null);
    const [initialSelectedLevels, setInitialSelectedLevels] = useState<ILevel[]>([]);

    useEffect(() => {
        dispatch(fetchLevels({ page, limit: rowsLimit, searchTerm }));
    }, [page, rowsLimit, searchTerm]);

    useEffect(() => {
        if (errorLevels) {
            dispatch(addNotification({ message: errorLevels, type: MessageType.ERROR, tag: 'errorLevels' }));
        }
    }, [errorLevels]);

    const handleLevelSelect = (level: ILevel) => {
        setSelectedLevels((prevSelected) => {
            const isSelected = prevSelected.some((e) => e.Id === level.Id);
            if (isSelected) {
                return prevSelected.filter((e) => e.Id !== level.Id);
            } else {
                return [...prevSelected, level];
            }
        });
    };

    const handleMouseDown = (index: number) => {
        setIsDragging(true);
        setDragStartIndex(index);
        setInitialSelectedLevels(selectedLevels);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        setDragStartIndex(null);
    };

    const handleMouseEnter = (index: number) => {
        if (isDragging && dragStartIndex !== null) {
            const startIndex = Math.min(dragStartIndex, index);
            const endIndex = Math.max(dragStartIndex, index);
            const isDraggingBackward = index < dragStartIndex;

            const levelsInRange = levels.slice(startIndex, endIndex + 1);

            setSelectedLevels((prevSelected) => {
                // If dragging forward, add levels to the selection
                if (!isDraggingBackward) {
                    const updatedSelected = [...prevSelected, ...levelsInRange];
                    return updatedSelected.filter(
                        (level, idx, self) => self.findIndex(e => e.Id === level.Id) === idx
                    );
                }

                // If dragging backward, remove levels from the selection
                return initialSelectedLevels.filter(
                    level => !levelsInRange.some(e => e.Id === level.Id)
                );
            });
        }
    };

    return (
        <>
            <div className='grid grid-cols-3 items-center mb-6'>
                <div className='col-span-1'>
                    <h3 className='h3'>Livelli</h3>
                </div>
                <div className='col-span-2 flex items-center gap-5'>
                    <SearchBar value={searchTerm} onChange={setSearchTerm} />
                </div>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Selezionato</th>
                        <th>Nome</th>
                        <th>Stato</th>
                        <th>Obiettivo</th>
                    </tr>
                </thead>
                <tbody>
                    {loadingLevels && (
                        <tr>
                            <td colSpan={4}>
                                <Loading height='400px' />
                            </td>
                        </tr>
                    )}
                    {!loadingLevels && !errorLevels && levels.map((level, index) => {
                        const isSelected = selectedLevels.some((e) => e.Id === level.Id);
                        return (
                            <tr
                                key={level.Id}
                                className={`hover:bg-gray-200 cursor-pointer ${isSelected ? '!bg-blue-200' : ''}`}
                                onClick={() => handleLevelSelect(level)}
                                onMouseDown={() => handleMouseDown(index)}
                                onMouseUp={handleMouseUp}
                                onMouseEnter={() => handleMouseEnter(index)}
                            >
                                <td>{isSelected ? '✔️' : '❌'}</td>
                                <td>{level.Name}</td>
                                <td>{level.Status}</td>
                                <td>{level.Goal}</td>
                            </tr>
                        );
                    })}
                    {!loadingLevels && !errorLevels && levels.length === 0 && (
                        <tr>
                            <td colSpan={4} className='italic text-gray-600'>Nessun risultato trovato...</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <div className='flex items-center justify-between gap-10'>
                <span className='min-w-40 mt-3'>Selezionati: {selectedLevels.length}</span>
                <PaginationControls page={page} totalPages={pages} onPageChange={setPage} />
            </div>
        </>
    );
};

export default LevelsSelectionTable;
