import React from 'react';
import LatestAccesses from '../components/statistics/LatestAccesses';
import LevelsTotal from '../components/statistics/LevelsTotal';
import CompletionTimes from '../components/statistics/CompletionTimes';
import LevelsConclusion from '../components/statistics/LevelsConclusion';
import LevelsToPublish from '../components/LevelsToPublish';

function Home() {
	return (
		<section className='w-full h-full'>
			<h1 className='h1'>Dashboard</h1>

			<div className='grid grid-cols-2 gap-6 my-6'>

				<div className='col-span-1'>
					<LatestAccesses />
				</div>

				<div className='col-span-1'>
					<LevelsTotal />
				</div>

				<div className='col-span-1'>
					<CompletionTimes />
				</div>

				<div className='col-span-1'>
					<LevelsConclusion />
				</div>

				<div className='col-span-2'>
					<LevelsToPublish />
				</div>

			</div>

		</section>
	);
}

export default Home;
