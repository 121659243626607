import { UserSex } from ".";
import IAccount, { exampleAccount } from "./IAccount";
import ICompany, { exampleCompany } from "./ICompany";

export default interface IUserCompany {
    Id: number;
    Id_Account: number;
    Id_Company: number;
    Name: string;
    Surname: string;
    CF: string;
    Phone: string;
    Creation: string;
    Timestamp: string;

    //* NotMapped
    Sex: UserSex;
    Account: IAccount;
    Company: ICompany;
}

export const exampleUserCompany: IUserCompany = {
    Id: 0,
    Id_Account: 0,
    Id_Company: 0,
    Name: '',
    Surname: '',
    CF: '',
    Phone: '',
    Creation: '',
    Timestamp: '',

    Sex: UserSex.MALE,
    Account: exampleAccount,
    Company: exampleCompany,
};