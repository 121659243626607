import IUserCompany, { exampleUserCompany } from "./IUserCompany";

export default interface IEmployee {
    Id: number;
    Id_UserCompany: number;
    Badge: string;
    Department: string;
    Notes: string | null;
    Creation: string;
    Timestamp: string;

    //* NotMapped
    UserCompany: IUserCompany;
}

export const exampleEmployee: IEmployee = {
    Id: 0,
    Id_UserCompany: 0,
    Badge: '',
    Department: '',
    Notes: null,
    Creation: '',
    Timestamp: '',

    UserCompany: exampleUserCompany,
};