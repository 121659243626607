import React from 'react';
import LatestAccesses from '../components/statistics/LatestAccesses';
import LevelsTotal from '../components/statistics/LevelsTotal';
import CompletionTimes from '../components/statistics/CompletionTimes';
import LevelsConclusion from '../components/statistics/LevelsConclusion';
import LevelsToPublish from '../components/LevelsToPublish';
import TopFastestLevels from '../components/statistics/TopFastestLevels';
import GeneralStats from '../components/statistics/GeneralStats';
import LevelsGroup from '../components/statistics/LevelsGroup';
import AverageTimes from '../components/statistics/AverageTimes';
import CompletedLevels from '../components/statistics/CompletedLevels';

function Statistics() {
	return (
		<section className='w-full h-full'>
			<h1 className='h1'>Statistiche Globali</h1>

			<div className='grid grid-cols-2 gap-6 my-6'>

				<div className='col-span-1'>
					<LevelsConclusion />
				</div>

				<div className='col-span-1'>
					<GeneralStats />
				</div>

				<div className='col-span-1'>
					<CompletionTimes />
				</div>

				<div className='col-span-1'>
					<AverageTimes />
				</div>

				<div className='col-span-1'>
					<LatestAccesses />
				</div>

				<div className='col-span-1'>
					<LevelsGroup />
					{/* <TopFastestLevels /> */}
				</div>

				<div className='col-span-1'>
					<CompletedLevels />
				</div>

				<div className='col-span-1'>
					<LevelsTotal />
				</div>

			</div>

		</section>
	);
}

export default Statistics;
