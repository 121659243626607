
export interface ChartProps {
    datasetLabel?: string;
    labels: string[];
    data: number[];
}

export const chartOptions = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        tooltip: {
            enabled: true,
        },
    },
}

export const chartDataset = {
    backgroundColor: [
        '#ff6384', // Red
        '#36a2eb', // Blue
        '#ffce56', // Yellow
        '#4bc0c0', // Teal
        '#9966ff', // Purple
        '#ff9f40', // Orange
    ],
    borderWidth: 1,
}