import { HorizontalPosition, PositionType, VerticalPosition } from "."

export default interface IPosition {
    x: number,
    y: number,
    anchor: { horizontal: HorizontalPosition, vertical: VerticalPosition }
}

export const examplePosition: IPosition = {
    x: 0,
    y: 0,
    anchor: { horizontal: PositionType.RIGHT, vertical: PositionType.BOTTOM }
}
