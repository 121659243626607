import React from 'react';

interface IProps {
    steps: string[];
    currentStep: number;
}

function Stepper({ steps, currentStep }: IProps) {
    return (
        <div className='flex items-center space-x-4'>
            {steps.map((step, index) => (
                <React.Fragment key={index}>
                    <div className={`flex items-center space-x-2 ${index <= currentStep ? 'text-primary' : 'text-gray-700'}`}>
                        <div
                            className={`flex items-center justify-center w-8 h-8 rounded-full border ${index < currentStep
                                ? 'bg-primary border-primary text-white'
                                : index === currentStep
                                    ? 'border-primary bg-blue-100'
                                    : 'border-gray-500'
                                }`}
                        >
                            {index < currentStep ? '✓' : index + 1}
                        </div>
                        <div className='flex flex-col'>
                            <span className='font-medium'>{step}</span>
                        </div>
                    </div>
                    {index < steps.length - 1 && (
                        <div className={`flex-grow border-t ${index < currentStep ? 'border-primary' : 'border-gray-500'}`} />
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default Stepper;
