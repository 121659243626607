import React from 'react';
import BarChart from '../charts/BarChart';

function AverageTimes() {
    const data = [
        { title: 'Quiz', text: '15 min', time: 15 },
        { title: 'Raccolta Oggetti', text: '3 min', time: 3 },
        { title: 'Raggiungi Stanza', text: '1 min', time: 1 },
        { title: 'Stanza con Oggetti', text: '8 min', time: 8 },
        { title: 'Deposito Oggetti', text: '5 min', time: 5 },
    ];

    return (
        <section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg'>

            <h3 className='h3'>Tempi medi di completamento</h3>

            <div className='grid grid-cols-3 gap-6 my-3'>

                <div className='col-span-1'>
                    <div className='flex flex-col gap-2'>
                        {data.map((item, index) => (
                            <div key={index} className='flex flex-col items-center text-center'>
                                <span className='font-bold'>{item.title}</span>
                                <span>{item.text}</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='col-span-2'>
                    <BarChart
                        datasetLabel='Tempo (minuti)'
                        labels={data.map(item => item.title)}
                        data={data.map(item => item.time)}
                    />
                </div>

            </div>

            <button className='btn'>Statistiche dettagliate</button>

        </section>
    );
}

export default AverageTimes;