import { HorizontalPosition, PositionType, VerticalPosition } from "../types";
import IPosition from "../types/IPosition";

function calculatePopupPosition(e: React.MouseEvent): IPosition {
    const rect = e.currentTarget.getBoundingClientRect();
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    // Calculate available space
    const spaceRight = screenWidth - rect.right;
    const spaceLeft = rect.left;
    const spaceTop = rect.top;
    const spaceBottom = screenHeight - rect.bottom;

    // Determine horizontal position
    let horizontal: HorizontalPosition = PositionType.RIGHT;
    if (spaceRight < 200 && spaceLeft > 200) {
        horizontal = PositionType.LEFT;
    }

    // Determine vertical position
    let vertical: VerticalPosition = PositionType.BOTTOM;
    if (spaceBottom < 150 && spaceTop > 150) {
        vertical = PositionType.TOP;
    }

    return {
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY,
        anchor: { horizontal, vertical },
    };
}

export default calculatePopupPosition;
