import React, { useState } from 'react';
import PaginationControls from './utils/PaginationControls';
import { MdOpenInBrowser, MdOutlineClose, MdOutlineCopyAll } from 'react-icons/md';
import { CiMenuKebab } from 'react-icons/ci';
import { Link } from 'react-router-dom';
import calculatePopupPosition from '../utils/calculatePopupPosition';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { addNotification } from '../redux/slices/notificationSlice';
import { MessageType } from '../types';
import { hidePopup, showPopup } from '../redux/slices/actionsPopupSlice';
import { useActionsPopup } from '../context/ActionsPopupContext';
import { copyLinkToClipboard } from '../utils/functions';

function LevelsToPublish() {
    const dispatch = useAppDispatch();
    const { registerCallback } = useActionsPopup();
    const { actionsPopupVisible, activeRowIndex } = useAppSelector((state) => state.actionsPopup);

    const handleIconClick = (e: React.MouseEvent, index: number) => {
        e.stopPropagation();
        
        if (actionsPopupVisible && activeRowIndex === index) {
            // Se il popup è già visibile per questa riga, chiudilo
            dispatch(hidePopup());
            return;
        }

        registerCallback('edit', () => dispatch(addNotification({ message: 'modifica', type: MessageType.INFO })));
        registerCallback('assignEmployees', () => dispatch(addNotification({ message: 'assegna utenti', type: MessageType.INFO })));
        registerCallback('loadRooms', () => dispatch(addNotification({ message: 'carica stanze', type: MessageType.INFO })));

        // Use the utility function to calculate the position
        const position = calculatePopupPosition(e);

        dispatch(
            showPopup({
                position,
                activeRowIndex: index,
                menu: [
                    { title: 'Modifica', actionId: 'edit', iconId: 'edit', },
                    { title: 'Assegna utenti', actionId: 'assignEmployees', iconId: 'personAdd', },
                    { title: 'Carica stanze', actionId: 'loadRooms', iconId: 'upload', },
                ],
            })
        );
    };

    return (
        <section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg'>

            <div className='w-full flex items-center justify-between'>
                <h3 className='h3'>Livelli da pubblicare</h3>
                <button className='btn'>Livelli</button>
            </div>

            <table className='mt-3'>
                <thead>
                    <tr>
                        <th>Nome Livello</th>
                        <th>Obiettivo</th>
                        <th>Pacchetti</th>
                        <th>Dipendenti</th>
                        <th>Stanze caricate</th>
                        <th>Link Test</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {[
                        { name: 'Quiz Operaio', goal: 'Quiz', NumGroups: 3, NumEmployees: 11, rooms: { loaded: 4, total: 12 }, testLink: 'https://example.com' },
                        { name: 'Uscita Stanza', goal: 'Raggiungi Stanza', NumGroups: 2, NumEmployees: 6, rooms: { loaded: 1, total: 1 }, testLink: 'https://example.com' },
                        { name: 'Quiz Manager', goal: 'Quiz', NumGroups: 7, NumEmployees: 19, rooms: { loaded: 0, total: 5 }, testLink: 'https://example.com' },
                        { name: 'Accesso Cantiere', goal: 'Raccolta Oggetti', NumGroups: 1, NumEmployees: 8, rooms: { loaded: 2, total: 3 }, testLink: 'https://example.com' },
                    ].map((user, index) => (
                        <tr key={index}>
                            <td>{user.name}</td>
                            <td>{user.goal}</td>
                            <td>{user.NumGroups}</td>
                            <td>{user.NumEmployees}</td>
                            <td>{user.rooms.loaded} / {user.rooms.total} {user.rooms.loaded === user.rooms.total ? '✅' : (user.rooms.loaded === 0 ? '❌' : '🕒')}</td>
                            <td className='flex items-center justify-center gap-3'>
                                <Link to={user.testLink} target='_blank'>
                                    <MdOpenInBrowser size={28} />
                                </Link>
                                <button onClick={() => copyLinkToClipboard(dispatch, user.testLink)}>
                                    <MdOutlineCopyAll size={28} />
                                </button>
                            </td>
                            <td>
                                <button onClick={(e) => handleIconClick(e, index)}>
                                    {actionsPopupVisible && activeRowIndex === index ? (
                                        <MdOutlineClose size={28} /> // Mostra l'icona "X" se il popup è visibile per questa riga
                                    ) : (
                                        <CiMenuKebab size={28} /> // Mostra i tre puntini altrimenti
                                    )}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <PaginationControls page={1} totalPages={10} onPageChange={() => { }} />

        </section>
    );
}

export default LevelsToPublish;
