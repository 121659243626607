export default interface IAccount {
    Id: number;
    Email: string;
    Password: string;
    LastLogin: string | null;
    Creation: string;
    Timestamp: string;

    //* NotMapped
    NewPassword: string;
    NewPasswordConfirm: string;
}

export const exampleAccount: IAccount = {
    Id: 0,
    Email: '',
    Password: '',
    LastLogin: null,
    Creation: '',
    Timestamp: '',

    NewPassword: '',
    NewPasswordConfirm: '',
};
