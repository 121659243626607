import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MdDelete, MdEdit, MdOutlineScreenShare } from 'react-icons/md';
import { addNotification } from '../../redux/slices/notificationSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { MessageType, StatusEnum } from '../../types';
import { fetchLevelGroupById } from '../../redux/slices/levelGroupSlice';
import Loading from '../utils/Loading';
import { usePopup } from '../../context/PopupContext';
import { handleDeleteLevelGroup, handlePublishLevelGroup } from '../../utils/levelGroup-actions';

function SingleLevelGroup() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { registerCallback } = usePopup();
    const { selectedLevelGroup, loadingLevelGroups, errorLevelGroups } = useAppSelector((state) => state.levelGroup);

    useEffect(() => {
        if (id) {
            dispatch(fetchLevelGroupById(Number(id)));
        } else {
            navigate('/levels/groups');
        }
    }, [id]);

    useEffect(() => {
        if (errorLevelGroups) {
            dispatch(addNotification({ message: errorLevelGroups, type: MessageType.ERROR, tag: 'errorLevelGroups' }));
        }
    }, [errorLevelGroups]);

    return (
        <section>

            <h1 className='h1'>Pacchetto di Livelli</h1>

            <div className='flex flex-col gap-2 my-6'>
                <div className='flex items-center gap-3'>
                    <h5 className='h5'>Pacchetto:</h5>
                    <span>{selectedLevelGroup?.Name}</span>
                </div>

                <div className='flex items-center gap-3'>
                    <h5 className='h5'>Descrizione:</h5>
                    <span>{selectedLevelGroup?.Description ?? 'N/A'}</span>
                </div>

                <div className='flex items-center gap-3'>
                    <h5 className='h5'>Stato:</h5>
                    <span>{selectedLevelGroup?.Status} {selectedLevelGroup?.Status === StatusEnum.PUBLISHED ? '✅' : '🕒'}</span>
                </div>
            </div>

            <div className='flex items-center justify-between gap-6 mb-10'>
                <Link to='./edit' className='btn btn-lg'>Modifica <MdEdit size={20} /></Link>
                {selectedLevelGroup?.Status !== StatusEnum.PUBLISHED && (
                    <button className='btn btn-lg' onClick={() => handlePublishLevelGroup(dispatch, selectedLevelGroup, registerCallback)}>
                        Pubblica <MdOutlineScreenShare size={20} />
                    </button>
                )}
                <button className='btn btn-lg btn-danger' onClick={() => handleDeleteLevelGroup(dispatch, selectedLevelGroup, registerCallback, navigate)}>
                    Elimina <MdDelete size={20} />
                </button>
            </div>

            <div className='grid grid-cols-7 gap-6'>

                <div className='col-span-4'>

                    <div className='w-full p-6 bg-white rounded-2xl shadow-lg'>

                        <h3 className='h3'>Dipendenti assegnati</h3>

                        <table className='mt-3'>
                            <thead>
                                <tr>
                                    <th>Cognome</th>
                                    <th>Nome</th>
                                    <th>Reparto</th>
                                    <th>Matricola</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadingLevelGroups && (
                                    <tr>
                                        <td colSpan={4}>
                                            <Loading height='400px' />
                                        </td>
                                    </tr>
                                )}
                                {!loadingLevelGroups && !errorLevelGroups && selectedLevelGroup?.Employees.map((employee, index) => (
                                    <tr key={index}
                                        className='hover:bg-gray-200 cursor-pointer'
                                        onClick={() => navigate(`/employees/${employee.Id}`)}>
                                        <td>{employee.UserCompany.Surname}</td>
                                        <td>{employee.UserCompany.Name}</td>
                                        <td>{employee.Department}</td>
                                        <td>{employee.Badge}</td>
                                    </tr>
                                ))}
                                {!loadingLevelGroups && !errorLevelGroups && (!selectedLevelGroup || selectedLevelGroup?.Employees.length === 0) && (
                                    <tr>
                                        <td colSpan={4} className='italic text-gray-600'>Nessun dipendente assegnato</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>

                </div>

                <div className='col-span-3'>

                    <div className='w-full p-6 bg-white rounded-2xl shadow-lg'>

                        <h3 className='h3'>Livelli assegnati</h3>

                        <table className='mt-3'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Nome</th>
                                    <th>Obiettivo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadingLevelGroups && (
                                    <tr>
                                        <td colSpan={2}>
                                            <Loading height='400px' />
                                        </td>
                                    </tr>
                                )}
                                {!loadingLevelGroups && !errorLevelGroups && selectedLevelGroup?.Levels.map((level, index) => (
                                    <tr key={index}
                                        className='hover:bg-gray-200 cursor-pointer'
                                        onClick={() => navigate(`/levels/${level.Id}`)}>
                                        <td>{level.Status === StatusEnum.PUBLISHED ? '✅' : '🕒'}</td>
                                        <td>{level.Name}</td>
                                        <td>{level.Goal}</td>
                                    </tr>
                                ))}
                                {!loadingLevelGroups && !errorLevelGroups && (!selectedLevelGroup || selectedLevelGroup?.Levels.length === 0) && (
                                    <tr>
                                        <td colSpan={2} className='italic text-gray-600'>Nessun livello assegnato</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>

                </div>

            </div>

        </section>
    );
}

export default SingleLevelGroup;