import { AppDispatch } from '../redux/store'; // Tipo di dispatch
import { addNotification } from '../redux/slices/notificationSlice';
import { showPopup, hidePopup } from '../redux/slices/popupSlice';
import { deleteEmployee } from '../redux/slices/employeeSlice';
import { MessageType } from '../types';
import IEmployee from '../types/IEmployee';
import { forgotPassword } from '../redux/slices/authSlice';

// Funzione per gestire il reset password di un dipendente
export const handleResetPassword = async (
    dispatch: AppDispatch,
    employee: IEmployee | null,
    registerCallback: (id: string, callback: () => void) => void,
) => {
    if (!employee || !employee.UserCompany.Account.Email) {
        dispatch(addNotification({ message: 'Dipendente non valido', type: MessageType.ERROR, tag: 'deleteEmployee' }));
        return;
    }

    const callbackId = 'confirmResetPassword';
    registerCallback(callbackId, async () => {
        dispatch(forgotPassword(employee.UserCompany.Account.Email))
            .then((result) => {
                if (forgotPassword.fulfilled.match(result)) {
                    dispatch(addNotification({ message: 'Email di reset password inviata con successo', type: MessageType.SUCCESS }));
                    dispatch(hidePopup());
                }
            })
            .catch((error) => {
                dispatch(addNotification({ message: error || 'Errore durante la richiesta di reset password', type: MessageType.ERROR }));
            });
    });

    dispatch(
        showPopup({
            type: MessageType.ERROR,
            message: 'Sei sicuro di voler resettare la password? L\'utente riceverà un\'email con le istruzioni',
            onConfirmId: callbackId,
        })
    );
};

// Funzione per gestire la cancellazione di un dipendente
export const handleDeleteEmployee = async (
    dispatch: AppDispatch,
    employee: IEmployee | null,
    registerCallback: (id: string, callback: () => void) => void,
    navigate?: (path: string) => void,
) => {
    if (!employee) {
        dispatch(addNotification({ message: 'Dipendente non valido', type: MessageType.ERROR, tag: 'deleteEmployee' }));
        return;
    }

    const callbackId = 'confirmDeleteEmployee';
    registerCallback(callbackId, async () => {
        dispatch(deleteEmployee(employee.Id))
            .then((result) => {
                if (deleteEmployee.fulfilled.match(result)) {
                    dispatch(addNotification({ message: 'Dipendente eliminato con successo', type: MessageType.SUCCESS, tag: 'deleteEmployee' }));
                    dispatch(hidePopup());
                    navigate?.('/employees');
                }
            })
            .catch((error) => {
                dispatch(addNotification({ message: error || 'Errore durante la richiesta di eliminazione', type: MessageType.ERROR, tag: 'deleteEmployee' }));
            });
    });

    dispatch(
        showPopup({
            type: MessageType.ERROR,
            message: 'Sei sicuro di voler eliminare il dipendente? L\'operazione è irreversibile',
            onConfirmId: callbackId,
        })
    );
};
