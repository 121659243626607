import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import ILevelGroup from '../../types/ILevelGroup';
import ILevel from '../../types/ILevel';
import IEmployee from '../../types/IEmployee';
import { StatusEnum } from '../../types';

interface IState {
  levelGroups: ILevelGroup[];
  pages: number;
  selectedLevelGroup: ILevelGroup | null;
  newLevelGroup: ILevelGroup | null
  loadingLevelGroups: boolean;
  errorLevelGroups: string | null;
}

const initialState: IState = {
  levelGroups: [],
  pages: 0,
  selectedLevelGroup: null,
  newLevelGroup: null,
  loadingLevelGroups: false,
  errorLevelGroups: null,
};

// Fetch all levelGroups
export const fetchLevelGroups = createAsyncThunk(
  'levelGroup/fetchLevelGroups',
  async ({ page, limit, searchTerm }: { page: number, limit: number, searchTerm?: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/levels/groups${searchTerm ? `?s=${searchTerm}` : ''}`, {
        params: { page, limit },
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante il recupero dei levelGroup');
    }
  }
);

// Fetch a single levelGroup by id
export const fetchLevelGroupById = createAsyncThunk(
  'levelGroup/fetchLevelGroupById',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/levels/groups/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante il recupero del levelGroup');
    }
  }
);

// Create a new levelGroup
export const createLevelGroup = createAsyncThunk(
  'levelGroup/createLevelGroup',
  async (
    { levelGroup, levels, employees }: { levelGroup: ILevelGroup, levels: ILevel[], employees: IEmployee[] },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post('/levels/groups', { levelGroup, levels, employees });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante la creazione del levelGroup');
    }
  }
);

// Update an existing levelGroup
export const updateLevelGroup = createAsyncThunk(
  'levelGroup/updateLevelGroup',
  async (
    { id, levelGroup, levels, employees }: { id: number, levelGroup: ILevelGroup, levels: ILevel[], employees: IEmployee[] },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(`/levels/groups/${id}`, { levelGroup, levels, employees });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante l\'aggiornamento del levelGroup');
    }
  }
);

// Delete an existing levelGroup
export const deleteLevelGroup = createAsyncThunk(
  'levelGroup/deleteLevelGroup',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/levels/groups/${id}`);
      return { ...response.data, Id_LevelGroup: id };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante il recupero del levelGroup');
    }
  }
);

// Publish a levelGroup
export const publishLevelGroup = createAsyncThunk(
  'levelGroup/publishLevelGroup',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/levels/groups/${id}/publish`);
      return response.data;
    } catch (error: any) {
      console.log('error', error);
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante la pubblicazione del levelGroup');
    }
  }
);

const levelGroupSlice = createSlice({
  name: 'levelGroup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //* Fetch all levelGroups
      .addCase(fetchLevelGroups.pending, (state) => {
        state.loadingLevelGroups = true;
        state.errorLevelGroups = null;
      })
      .addCase(fetchLevelGroups.fulfilled, (state, action) => {
        state.loadingLevelGroups = false;
        state.levelGroups = action.payload.items;
        state.pages = action.payload.pages;
      })
      .addCase(fetchLevelGroups.rejected, (state, action) => {
        state.loadingLevelGroups = false;
        state.levelGroups = [];
        state.pages = 0;
        state.errorLevelGroups = action.payload as string || 'Failed to fetch levelGroups';
      })
      //* Fetch a single levelGroup by id
      .addCase(fetchLevelGroupById.pending, (state) => {
        state.loadingLevelGroups = true;
        state.errorLevelGroups = null;
      })
      .addCase(fetchLevelGroupById.fulfilled, (state, action) => {
        state.loadingLevelGroups = false;
        state.selectedLevelGroup = action.payload;
      })
      .addCase(fetchLevelGroupById.rejected, (state, action) => {
        state.loadingLevelGroups = false;
        state.selectedLevelGroup = null;
        state.errorLevelGroups = action.payload as string || 'Failed to fetch the levelGroup';
      })
      //* Create a new levelGroup
      .addCase(createLevelGroup.pending, (state) => {
        state.loadingLevelGroups = true;
        state.errorLevelGroups = null;
      })
      .addCase(createLevelGroup.fulfilled, (state, action) => {
        state.loadingLevelGroups = false;
        state.newLevelGroup = action.payload;
      })
      .addCase(createLevelGroup.rejected, (state, action) => {
        state.loadingLevelGroups = false;
        state.errorLevelGroups = action.payload as string || 'Failed to create levelGroup';
      })
      //* Update an existing levelGroup
      .addCase(updateLevelGroup.pending, (state) => {
        state.loadingLevelGroups = true;
        state.errorLevelGroups = null;
      })
      .addCase(updateLevelGroup.fulfilled, (state, action) => {
        state.loadingLevelGroups = false;
      })
      .addCase(updateLevelGroup.rejected, (state, action) => {
        state.loadingLevelGroups = false;
        state.errorLevelGroups = action.payload as string || 'Failed to update levelGroup';
      })
      //* Delete an existing levelGroup
      .addCase(deleteLevelGroup.pending, (state) => {
        state.loadingLevelGroups = true;
        state.errorLevelGroups = null;
      })
      .addCase(deleteLevelGroup.fulfilled, (state, action) => {
        state.loadingLevelGroups = false;
        state.selectedLevelGroup = null;
        state.levelGroups = state.levelGroups.filter((levelGroup) => levelGroup.Id !== action.payload.Id_LevelGroup);
      })
      .addCase(deleteLevelGroup.rejected, (state, action) => {
        state.loadingLevelGroups = false;
        state.errorLevelGroups = action.payload as string || 'Failed to delete levelGroup';
      })
      //* Publish a levelGroup
      .addCase(publishLevelGroup.pending, (state) => {
        state.loadingLevelGroups = true;
        state.errorLevelGroups = null;
      })
      .addCase(publishLevelGroup.fulfilled, (state, action) => {
        state.loadingLevelGroups = false;
        if (state.selectedLevelGroup && state.selectedLevelGroup.Id === action.meta.arg) {
          state.selectedLevelGroup = {
            ...state.selectedLevelGroup,
            Status: StatusEnum.PUBLISHED,
            Levels: state.selectedLevelGroup.Levels.map(level => ({ ...level, Status: StatusEnum.PUBLISHED })),
          };
        }
        state.levelGroups = state.levelGroups.map(levelGroup =>
          levelGroup.Id === action.meta.arg
            ? {
              ...levelGroup,
              Status: StatusEnum.PUBLISHED,
              Levels: levelGroup.Levels.map(level => ({ ...level, Status: StatusEnum.PUBLISHED })),
            }
            : levelGroup
        );
      })
      .addCase(publishLevelGroup.rejected, (state, action) => {
        state.loadingLevelGroups = false;
        state.errorLevelGroups = action.payload as string || 'Failed to publish level';
      });
  },
});

export default levelGroupSlice.reducer;
