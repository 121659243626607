import React from 'react';
import { PageType } from '../types';
import LevelsGroupsTable from '../components/levels/LevelGroupsTable';
import SingleLevelGroup from '../components/levels/SingleLevelGroup';
import LevelGroupForm from '../components/levels/LevelGroupForm';

interface IProps {
	type: PageType;
}

function LevelGroups({ type }: IProps) {
	return (
		<section className='w-full h-full'>

			{type === PageType.ALL && <LevelsGroupsTable />}
			{type === PageType.SINGLE && <SingleLevelGroup />}
			{type === PageType.CREATE && <LevelGroupForm type={PageType.CREATE} />}
			{type === PageType.EDIT && <LevelGroupForm type={PageType.EDIT} />}

		</section>
	);
}

export default LevelGroups;
