import React, { useEffect, useState } from 'react';
import { MdAdd, MdOutlineClose } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import PaginationControls from '../utils/PaginationControls';
import { CiMenuKebab } from 'react-icons/ci';
import SearchBar from '../utils/SearchBar';
import calculatePopupPosition from '../../utils/calculatePopupPosition';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchEmployees } from '../../redux/slices/employeeSlice';
import { MessageType, UserSex } from '../../types';
import Loading from '../utils/Loading';
import IEmployee from '../../types/IEmployee';
import { addNotification } from '../../redux/slices/notificationSlice';
import { hidePopup as hideActionsPopup, showPopup as showActionsPopup } from '../../redux/slices/actionsPopupSlice';
import { useActionsPopup } from '../../context/ActionsPopupContext';
import { usePopup } from '../../context/PopupContext';
import { handleDeleteEmployee, handleResetPassword } from '../../utils/employee-actions';

function EmployeesTable() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { registerCallback: registerActionsPopupCallback } = useActionsPopup();
    const { registerCallback: registerConfirmationPopupCallback } = usePopup();
    const { employees, pages, loadingEmployees, errorEmployees } = useAppSelector((state) => state.employee);
    const { actionsPopupVisible, activeRowIndex } = useAppSelector((state) => state.actionsPopup);
    const { loadingAuth } = useAppSelector((state) => state.auth);

    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);

    useEffect(() => {
        dispatch(fetchEmployees({ page, limit: 15, searchTerm }));
    }, [dispatch, page, searchTerm]);

    useEffect(() => {
        if (errorEmployees) {
            dispatch(addNotification({ message: errorEmployees, type: MessageType.ERROR }));
        }
    }, [errorEmployees]);

    const handleIconClick = (e: React.MouseEvent, index: number, employee: IEmployee) => {
        e.stopPropagation();

        if (actionsPopupVisible && activeRowIndex === index) {
            // Se il popup è già visibile per questa riga, chiudilo
            dispatch(hideActionsPopup());
            return;
        }

        registerActionsPopupCallback('edit', () => {
            if (employee && employee.Id) {
                navigate(`./${employee.Id}/edit`);
            } else {
                dispatch(addNotification({ message: "Impossibile modificare l'utente", type: MessageType.ERROR }));
            }
        });
        registerActionsPopupCallback('resetPassword', () => handleResetPassword(dispatch, employee, registerConfirmationPopupCallback));
        registerActionsPopupCallback('delete', () => handleDeleteEmployee(dispatch, employee, registerConfirmationPopupCallback));

        // Use the utility function to calculate the position
        const position = calculatePopupPosition(e);

        dispatch(
            showActionsPopup({
                position,
                activeRowIndex: index,
                menu: [
                    { title: 'Modifica', actionId: 'edit', iconId: 'edit', },
                    { title: 'Reset password', actionId: 'resetPassword', iconId: 'reset', },
                    { title: 'Elimina', actionId: 'delete', iconId: 'delete', },
                ],
            })
        );
    };

    return (
        <section>

            <div className='grid grid-cols-3 items-center mb-6'>
                <div className='col-span-1'>
                    <h1 className='h1'>Dipendenti</h1>
                </div>
                <div className='col-span-2 flex items-center gap-5'>
                    <SearchBar value={searchTerm} onChange={setSearchTerm} />
                    <Link to={'./create'} className='btn'>Aggiungi <MdAdd size={18} /></Link>
                </div>
            </div>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Cognome</th>
                        <th>Nome</th>
                        <th>Reparto</th>
                        <th>Matricola</th>
                        <th>Livelli</th>
                        <th>Ultimo accesso</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {loadingEmployees && (
                        <tr>
                            <td colSpan={8}>
                                <Loading height='400px' />
                            </td>
                        </tr>
                    )}
                    {!loadingEmployees && !errorEmployees && employees.map((employee, index) => (
                        <tr key={index}
                            className='hover:bg-gray-200 cursor-pointer'
                            onClick={() => navigate(`./${employee.Id}`)}
                        >
                            <td>
                                {employee.UserCompany?.Sex === UserSex.MALE ? '👦🏻' :
                                    employee.UserCompany?.Sex === UserSex.FEMALE ? '👧🏻' : 'N/A'}
                            </td>
                            <td>{employee.UserCompany.Surname}</td>
                            <td>{employee.UserCompany.Name}</td>
                            <td>{employee.Department}</td>
                            <td>{employee.Badge}</td>
                            <td>{/* employee.Levels.Completed */} ✅ | {/* employee.Levels.Waiting */} 🕒 | {/* employee.Levels.Failed */} ❌ // TODO: Implementare logica Levels</td>
                            <td>{employee.UserCompany.Account.LastLogin ?? 'N/A'}</td>
                            <td>
                                <button onClick={(e) => handleIconClick(e, index, employee)}>
                                    {actionsPopupVisible && activeRowIndex === index ? (
                                        <MdOutlineClose size={28} /> // Mostra l'icona "X" se il popup è visibile per questa riga
                                    ) : (
                                        <CiMenuKebab size={28} /> // Mostra i tre puntini altrimenti
                                    )}
                                </button>
                            </td>
                        </tr>
                    ))}
                    {!loadingEmployees && !errorEmployees && employees.length === 0 && (
                        <tr>
                            <td colSpan={8} className='italic text-gray-600'>Nessun risultato trovato...</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <PaginationControls page={page} totalPages={pages} onPageChange={(newPage) => setPage(newPage)} />

            {loadingAuth && // Mostra il loader se l'utente sta resettando la password
                <div className='flex justify-end'>
                    <Loading width='200px' height='0' />
                </div>
            }
        </section>
    );
}

export default EmployeesTable;
