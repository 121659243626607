import React, { useEffect, useRef, useState } from 'react';
import { MdChevronRight } from 'react-icons/md';

interface Option {
    value: string;
    label: string;
}

interface IProps {
    options: Option[];
    selectedOptions: string[];
    onChange: (selected: string[]) => void;
}

function MultiSelect({ options, selectedOptions, onChange }: IProps) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSelection = (value: string) => {
        if (selectedOptions.includes(value)) {
            onChange(selectedOptions.filter((item) => item !== value));
        } else {
            onChange([...selectedOptions, value]);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    return (
        <div className='w-full relative normal-case' ref={dropdownRef}>
            {/* Dropdown toggle */}
            <div
                className='border border-gray-300 rounded-lg px-2 py-1.5 bg-white cursor-pointer'
                onClick={() => setIsOpen((prev) => !prev)}
            >
                <div className='relative flex flex-wrap gap-2'>
                    {selectedOptions.length > 0 ? (
                        selectedOptions.map((selected) => (
                            <span
                                key={selected}
                                className='bg-blue-100 text-blue-700 px-2 py-0.5 rounded-full text-sm'
                            >
                                {options.find((option) => option.value === selected)?.label}
                            </span>
                        ))
                    ) : (
                        <span className='text-gray-500'>Seleziona...</span>
                    )}
                    <MdChevronRight size={20} className='absolute right-0 top-1/2 -translate-y-1/2 rotate-90' />
                </div>
            </div>

            {/* Dropdown menu */}
            {isOpen && (
                <div className='absolute top-full left-0 w-full border border-gray-300 rounded-lg bg-white mt-1 max-h-60 overflow-y-auto shadow-lg z-10'>
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className='flex items-center gap-2 cursor-pointer hover:bg-gray-100 px-2 py-1'
                            onClick={() => handleSelection(option.value)}
                        >
                            <input
                                type='checkbox'
                                checked={selectedOptions.includes(option.value)}
                                onChange={() => handleSelection(option.value)}
                                className='max-w-4 h-4 cursor-pointer'
                            />
                            <label className='flex-1 cursor-pointer'>{option.label}</label>
                        </div>
                    ))}
                    {options.length === 0 && (
                        <div className='px-2 py-1 italic text-gray-600'>Nessun elemento trovato</div>
                    )}
                </div>
            )}
        </div>
    );
}

export default MultiSelect;
