import React from 'react';
import { PageType } from '../types';
import EmployeesTable from '../components/employees/EmployeesTable';
import EmployeeForm from '../components/employees/EmployeeForm';

interface IProps {
	type: PageType;
}

function Employees({ type }: IProps) {
	return (
		<section className='w-full h-full'>
			
			{type === PageType.ALL && <EmployeesTable />}
			{type === PageType.SINGLE && <EmployeeForm type={PageType.SINGLE} />}
			{type === PageType.CREATE && <EmployeeForm type={PageType.CREATE} />}
			{type === PageType.EDIT && <EmployeeForm type={PageType.EDIT} />}

		</section>
	);
}

export default Employees;
