import React, { useEffect, useState } from 'react';
import Loading from '../utils/Loading';
import PaginationControls from '../utils/PaginationControls';
import IEmployee from '../../types/IEmployee';
import SearchBar from '../utils/SearchBar';
import { addNotification } from '../../redux/slices/notificationSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { MessageType } from '../../types';
import { fetchEmployees } from '../../redux/slices/employeeSlice';

interface IProps {
    rowsLimit?: number;
    selectedEmployees: IEmployee[];
    setSelectedEmployees: React.Dispatch<React.SetStateAction<IEmployee[]>>;
}

function EmployeesSelectionTable({ rowsLimit = 15, selectedEmployees, setSelectedEmployees }: IProps) {
    const dispatch = useAppDispatch();
    const { employees, pages, loadingEmployees, errorEmployees } = useAppSelector((state) => state.employee);

    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStartIndex, setDragStartIndex] = useState<number | null>(null);
    const [initialSelectedEmployees, setInitialSelectedEmployees] = useState<IEmployee[]>([]);

    useEffect(() => {
        dispatch(fetchEmployees({ page, limit: rowsLimit, searchTerm }));
    }, [page, rowsLimit, searchTerm]);

    useEffect(() => {
        if (errorEmployees) {
            dispatch(addNotification({ message: errorEmployees, type: MessageType.ERROR, tag: 'errorEmployees' }));
        }
    }, [errorEmployees]);

    const handleEmployeeSelect = (employee: IEmployee) => {
        setSelectedEmployees((prevSelected) => {
            const isSelected = prevSelected.some((e) => e.Id === employee.Id);
            if (isSelected) {
                return prevSelected.filter((e) => e.Id !== employee.Id);
            } else {
                return [...prevSelected, employee];
            }
        });
    };

    const handleMouseDown = (index: number) => {
        setIsDragging(true);
        setDragStartIndex(index);
        setInitialSelectedEmployees(selectedEmployees);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        setDragStartIndex(null);
    };

    const handleMouseEnter = (index: number) => {
        if (isDragging && dragStartIndex !== null) {
            const startIndex = Math.min(dragStartIndex, index);
            const endIndex = Math.max(dragStartIndex, index);
            const isDraggingBackward = index < dragStartIndex;

            const employeesInRange = employees.slice(startIndex, endIndex + 1);

            setSelectedEmployees((prevSelected) => {
                // If dragging forward, add employees to the selection
                if (!isDraggingBackward) {
                    const updatedSelected = [...prevSelected, ...employeesInRange];
                    return updatedSelected.filter(
                        (employee, idx, self) => self.findIndex(e => e.Id === employee.Id) === idx
                    );
                }

                // If dragging backward, remove employees from the selection
                return initialSelectedEmployees.filter(
                    employee => !employeesInRange.some(e => e.Id === employee.Id)
                );
            });
        }
    };

    return (
        <>
            <div className='grid grid-cols-3 items-center mb-6'>
                <div className='col-span-1'>
                    <h3 className='h3'>Dipendenti</h3>
                </div>
                <div className='col-span-2 flex items-center gap-5'>
                    <SearchBar value={searchTerm} onChange={setSearchTerm} />
                </div>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Selezionato</th>
                        <th>Cognome</th>
                        <th>Nome</th>
                        <th>Reparto</th>
                        <th>Matricola</th>
                    </tr>
                </thead>
                <tbody>
                    {loadingEmployees && (
                        <tr>
                            <td colSpan={5}>
                                <Loading height='400px' />
                            </td>
                        </tr>
                    )}
                    {!loadingEmployees && !errorEmployees && employees.map((employee, index) => {
                        const isSelected = selectedEmployees.some((e) => e.Id === employee.Id);
                        return (
                            <tr
                                key={employee.Id}
                                className={`hover:bg-gray-200 cursor-pointer ${isSelected ? '!bg-blue-200' : ''}`}
                                onClick={() => handleEmployeeSelect(employee)}
                                onMouseDown={() => handleMouseDown(index)}
                                onMouseUp={handleMouseUp}
                                onMouseEnter={() => handleMouseEnter(index)}
                            >
                                <td>{isSelected ? '✔️' : '❌'}</td>
                                <td>{employee.UserCompany.Surname}</td>
                                <td>{employee.UserCompany.Name}</td>
                                <td>{employee.Department}</td>
                                <td>{employee.Badge}</td>
                            </tr>
                        );
                    })}
                    {!loadingEmployees && !errorEmployees && employees.length === 0 && (
                        <tr>
                            <td colSpan={6} className='italic text-gray-600'>Nessun risultato trovato...</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <div className='flex items-center justify-between gap-10'>
                <span className='min-w-40 mt-3'>Selezionati: {selectedEmployees.length}</span>
                <PaginationControls page={page} totalPages={pages} onPageChange={setPage} />
            </div>
        </>
    );
};

export default EmployeesSelectionTable;
