import { AppDispatch } from '../redux/store'; // Tipo di dispatch
import { addNotification } from '../redux/slices/notificationSlice';
import { showPopup, hidePopup } from '../redux/slices/popupSlice';
import { deleteLevel, publishLevel } from '../redux/slices/levelSlice';
import { MessageType, StatusEnum } from '../types';
import ILevel from '../types/ILevel';

// Funzione per gestire la pubblicazione di un livello
export const handlePublishLevel = async (
    dispatch: AppDispatch,
    level: ILevel | null,
    registerCallback: (id: string, callback: () => void) => void,
) => {
    if (!level) {
        dispatch(addNotification({ message: 'Livello non valido', type: MessageType.ERROR, tag: 'publishLevel' }));
        return;
    }

    if (level.Status === StatusEnum.PUBLISHED) {
        dispatch(addNotification({ message: 'Il livello è già stato pubblicato', type: MessageType.WARNING, tag: 'publishLevel' }));
        return;
    } else if (level.RoomsNumber === 0) {
        dispatch(addNotification({ message: 'Il livello non ha stanze assegnate', type: MessageType.WARNING, tag: 'publishLevel' }));
        return;
    } else if (level.LevelGroups.length === 0) {
        dispatch(addNotification({ message: 'Il livello non è assegnato a nessun pacchetto', type: MessageType.WARNING, tag: 'publishLevel' }));
        return;
    } else if (level.LevelGroups.every(lg => lg.Employees.length === 0)) {
        dispatch(addNotification({ message: 'Il livello è assegnato a pacchetti senza dipendenti', type: MessageType.WARNING, tag: 'publishLevel' }));
        return;
    }

    const callbackId = 'confirmPublishLevel';
    registerCallback(callbackId, async () => {
        dispatch(publishLevel(level.Id))
            .then((result) => {
                if (publishLevel.fulfilled.match(result)) {
                    dispatch(addNotification({ message: 'Livello pubblicato con successo', type: MessageType.SUCCESS, tag: 'publishLevel' }));
                    dispatch(hidePopup());
                }
            })
            .catch((error) => {
                dispatch(addNotification({ message: error || 'Errore durante la richiesta di pubblicazione', type: MessageType.ERROR, tag: 'publishLevel' }));
            });
    });

    dispatch(
        showPopup({
            type: MessageType.INFO,
            message: 'Sei sicuro di voler pubblicare il livello?',
            onConfirmId: callbackId,
        })
    );
};

// Funzione per gestire la cancellazione di un livello
export const handleDeleteLevel = async (
    dispatch: AppDispatch,
    level: ILevel | null,
    registerCallback: (id: string, callback: () => void) => void,
    navigate?: (path: string) => void,
) => {
    if (!level) {
        dispatch(addNotification({ message: 'Livello non valido', type: MessageType.ERROR, tag: 'deleteLevel' }));
        return;
    }

    const callbackId = 'confirmDeleteLevel';
    registerCallback(callbackId, async () => {
        dispatch(deleteLevel(level.Id))
            .then((result) => {
                if (deleteLevel.fulfilled.match(result)) {
                    dispatch(addNotification({ message: 'Livello eliminato con successo', type: MessageType.SUCCESS, tag: 'deleteLevel' }));
                    dispatch(hidePopup());
                    navigate?.('/levels');
                }
            })
            .catch((error) => {
                dispatch(addNotification({ message: error || 'Errore durante la richiesta di eliminazione', type: MessageType.ERROR, tag: 'deleteLevel' }));
            });
    });

    dispatch(
        showPopup({
            type: MessageType.ERROR,
            message: 'Sei sicuro di voler eliminare il livello? L\'operazione è irreversibile',
            onConfirmId: callbackId,
        })
    );
};
