import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

interface IState {
  token: string | null;
  idAccount: number | null;
  role: string | null;
  fullName: string | null;
  loadingAuth: boolean;
  errorAuth: string | null;
}

const initialState: IState = {
  token: localStorage.getItem('PlayTraining_authToken') || null,
  idAccount: Number(localStorage.getItem('PlayTraining_idAccount')) || null,
  role: localStorage.getItem('PlayTraining_role') || null,
  fullName: localStorage.getItem('PlayTraining_fullName') || null,
  loadingAuth: false,
  errorAuth: null,
};

export const login = createAsyncThunk(
  'auth/login',
  async ({ Email, Password }: { Email: string; Password: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/auth/login', { Email, Password });
      localStorage.setItem('PlayTraining_authToken', response.data.accessToken); // Persist token
      localStorage.setItem('PlayTraining_idAccount', response.data.idAccount); // Persist idAccount
      localStorage.setItem('PlayTraining_role', response.data.role); // Persist role
      localStorage.setItem('PlayTraining_fullName', response.data.fullName); // Persist fullName
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Errore di autenticazione');
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      await axiosInstance.post('/auth/logout');
      localStorage.removeItem('PlayTraining_authToken');
      localStorage.removeItem('PlayTraining_idAccount');
      localStorage.removeItem('PlayTraining_role');
      localStorage.removeItem('PlayTraining_fullName');
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Errore durante il logout');
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/auth/forgot-password', { email });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Errore di invio richiesta reset password');
    }
  }
);

// export const sendResetLink = createAsyncThunk(
//   'auth/sendResetLink',
//   async (email: string, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.post('/auth/forgot-password', { email });
//       return response.data;
//     } catch (error: any) {
//       return rejectWithValue(error.response?.data?.message || 'Errore di invio richiesta reset password');
//     }
//   }
// );

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ token, newPassword }: { token: string, newPassword: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/auth/reset-password', { token, newPassword });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Errore di reset password');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //* Login
      .addCase(login.pending, (state) => {
        state.loadingAuth = true;
        state.errorAuth = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loadingAuth = false;
        state.token = action.payload.accessToken;
        state.idAccount = action.payload.idAccount;
        state.role = action.payload.role;
        state.fullName = action.payload.fullName;
      })
      .addCase(login.rejected, (state, action) => {
        state.loadingAuth = false;
        state.token = null;
        state.idAccount = null;
        state.role = null;
        state.fullName = null;
        state.errorAuth = action.payload as string;
      })
      //* Logout
      .addCase(logout.pending, (state) => {
        state.loadingAuth = true;
        state.errorAuth = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loadingAuth = false;
        state.token = null;
        state.idAccount = null;
        state.role = null;
        state.fullName = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loadingAuth = false;
        state.errorAuth = action.payload as string;
      })
      //* Forgot Password
      .addCase(forgotPassword.pending, (state) => {
        state.loadingAuth = true;
        state.errorAuth = null;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.loadingAuth = false;
        // state.token = null;
        // state.idAccount = null;
        // state.role = null;
        // state.fullName = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loadingAuth = false;
        state.errorAuth = action.payload as string;
      })
      // //* Send Reset Link
      // .addCase(sendResetLink.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(sendResetLink.fulfilled, (state) => {
      //   state.loading = false;
      // })
      // .addCase(sendResetLink.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload as string;
      // })
      //* Reset Password
      .addCase(resetPassword.pending, (state) => {
        state.loadingAuth = true;
        state.errorAuth = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loadingAuth = false;
        // state.token = null;
        // state.idAccount = null;
        // state.role = null;
        // state.fullName = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loadingAuth = false;
        state.errorAuth = action.payload as string;
      });
  },
});

export default authSlice.reducer;
