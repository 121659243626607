import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ProfileCard from './ProfileCard';
import { MdDashboard, MdFileUpload, MdGroupWork, MdMap, MdOutlineDriveFolderUpload, MdPeople, MdQueryStats, MdSettings } from 'react-icons/md';
import KMsolution_white from '../../images/KMsolution_white.svg';


function Sidebar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const location = useLocation();

  function toggleDropdown() {
    setIsDropdownOpen(!isDropdownOpen);
  }

  return (
    <aside className='w-[18vw] 3xl:w-[13vw] h-[96vh] px-3 py-8 rounded-tr-3xl bg-blue-600 text-white overflow-auto fixed left-0 bottom-0'>
      <div className='h-full flex flex-col justify-between'>
        <div>
          <div className='flex justify-center'>
            <img className='h-6' src={KMsolution_white} alt='Logo' />
          </div>
          <nav className='flex flex-col my-12'>
            {[
              { title: 'Dashboard', url: '/', icon: <MdDashboard size={20} /> },
              { title: 'Dipendenti', url: '/employees', icon: <MdPeople size={20} /> },
              { title: 'Pacchetti di Livelli', url: '/levels/groups', icon: <MdGroupWork size={20} /> },
              { title: 'Crea Pacchetto', url: '/levels/groups/create', icon: <MdOutlineDriveFolderUpload size={20} /> },
              { title: 'Livelli', url: '/levels', icon: <MdMap size={20} /> },
              { title: 'Crea Livello', url: '/levels/create', icon: <MdFileUpload size={20} /> },
              { title: 'Statistiche', url: '/statistics', icon: <MdQueryStats size={20} /> },
              { title: 'Impostazioni', url: '/settings', icon: <MdSettings size={20} /> },
            ].map((item, index) => (
              <Link key={index} to={item.url}
                className={`flex items-center gap-3 my-2 px-3 py-2 font-medium rounded-lg border border-white
                ${location.pathname === item.url ?
                  'bg-white text-black border border-blue-600' :
                  'hover:bg-blue-200 hover:text-black hover:border-transparent'}`}
              >
                {item.icon} {item.title}
              </Link>
            ))}
          </nav>
        </div>
        <ProfileCard />
      </div>
    </aside>
  );
}

export default Sidebar;