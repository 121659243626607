import React, { useEffect, useState } from 'react';
import Stepper from '../utils/Stepper';
import { MdChevronLeft, MdChevronRight, MdSave } from 'react-icons/md';
import { MessageType, PageType, PageTypeForm } from '../../types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import ILevelGroup, { exampleLevelGroup } from '../../types/ILevelGroup';
import { addNotification } from '../../redux/slices/notificationSlice';
import IEmployee from '../../types/IEmployee';
import EmployeesSelectionTable from '../employees/EmployeesSelectionTable';
import LevelsSelectionTable from './LevelsSelectionTable';
import ILevel from '../../types/ILevel';
import { createLevelGroup, fetchLevelGroupById, updateLevelGroup } from '../../redux/slices/levelGroupSlice';
import { useNavigate, useParams } from 'react-router-dom';

interface IProps {
    type: PageTypeForm;
}

function LevelGroupForm({ type }: IProps) {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { loadingLevelGroups, errorLevelGroups } = useAppSelector((state) => state.levelGroup);

    const [formData, setFormData] = useState<ILevelGroup>(exampleLevelGroup);
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedLevels, setSelectedLevels] = useState<ILevel[]>([]);
    const [selectedEmployees, setSelectedEmployees] = useState<IEmployee[]>([]);

    const steps = ['Informazioni', 'Livelli', 'Dipendenti'];

    useEffect(() => {
        if (id) {
            dispatch(fetchLevelGroupById(Number(id)))
                .then((result) => {
                    if (fetchLevelGroupById.fulfilled.match(result)) {
                        setFormData(result.payload);
                        setSelectedLevels(result.payload.Levels);
                        setSelectedEmployees(result.payload.Employees);
                    }
                });
        } else if (type === PageType.SINGLE || type === PageType.EDIT) {
            navigate('/levels/groups');
        }
    }, [id]);

    useEffect(() => {
        if (errorLevelGroups) {
            dispatch(addNotification({ message: errorLevelGroups, type: MessageType.ERROR, tag: 'errorLevelGroups' }));
        }
    }, [errorLevelGroups]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        let formattedValue = value;

        // Dividi il nome per individuare la struttura annidata, se presente
        const nameParts = name.split('.');

        setFormData((prevData) => {
            // Clona l'oggetto corrente di formData
            const updatedData = { ...prevData };

            // Naviga attraverso l'oggetto fino all'ultimo campo
            let currentLevel: any = updatedData;
            for (let i = 0; i < nameParts.length - 1; i++) {
                currentLevel[nameParts[i]] = { ...currentLevel[nameParts[i]] }; // Clona ogni livello per evitare la mutazione dello stato originale
                currentLevel = currentLevel[nameParts[i]];
            }

            const currentField = nameParts[nameParts.length - 1];

            if (currentField === 'Name') {
                formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
            }

            // Aggiorna il valore del campo specifico
            currentLevel[currentField] = formattedValue;

            return updatedData;
        });
    };

    const handleNext = () => {
        if (isValidStep(currentStep)) {
            setCurrentStep(prev => prev + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep(prev => prev - 1);
        }
    };

    const isValidStep = (step: number) => {
        if (step >= steps.length) {
            dispatch(addNotification({ message: 'Non ci sono più passaggi successivi', type: MessageType.WARNING, tag: 'errorLevelGroupFields' }));
            return false;
        }

        if (step === 0) {
            if (!formData.Name) {
                dispatch(addNotification({ message: 'Compila tutti i campi obbligatori', type: MessageType.WARNING, tag: 'errorLevelGroupFields' }));
                return false;
            }
        }

        //* SE non permetto selezione livelli vuota si crea un loop in cui non si può procedere (Level richiede LevelGroup e viceversa)
        // if (step === 1) {
        //     if (selectedLevels.length === 0) {
        //         dispatch(addNotification({ message: 'Seleziona almeno un livello', type: MessageType.WARNING, tag: 'errorLevelGroupFields' }));
        //         return false;
        //     }
        // }

        // if (step === 2) {
        //     if (selectedEmployees.length === 0) {
        //         dispatch(addNotification({ message: 'Seleziona almeno un dipendente', type: MessageType.WARNING, tag: 'errorLevelGroupFields' }));
        //         return false;
        //     }
        // }

        return true;
    };

    const handleSave = () => {
        for (let i = 0; i < steps.length; i++) {
            if (!isValidStep(i)) {
                setCurrentStep(i);
                return;
            }
        }

        if (type === PageType.EDIT && id) {
            dispatch(updateLevelGroup({ id: Number(id), levelGroup: formData, levels: selectedLevels, employees: selectedEmployees }))
                .then((result) => {
                    if (updateLevelGroup.fulfilled.match(result)) {
                        dispatch(addNotification({ message: 'Pacchetto di livelli aggiornato con successo', type: MessageType.SUCCESS }));
                        navigate(`/levels/groups/${id}`);
                    }
                })
                .catch((error) => {
                    dispatch(addNotification({ message: error ?? 'Errore durante l\'aggiornamento del pacchetto di livelli', type: MessageType.ERROR }));
                });
        } else if (type === PageType.CREATE) {
            dispatch(createLevelGroup({ levelGroup: formData, levels: selectedLevels, employees: selectedEmployees }))
                .then((result) => {
                    if (createLevelGroup.fulfilled.match(result)) {
                        dispatch(addNotification({ message: 'Pacchetto di livelli creato con successo', type: MessageType.SUCCESS }));
                        navigate('/levels/groups');
                    }
                })
                .catch((error) => {
                    dispatch(addNotification({ message: error ?? 'Errore durante la creazione del pacchetto di livelli', type: MessageType.ERROR }));
                });
        } else {
            dispatch(addNotification({ message: 'Azione non riconosciuta', type: MessageType.WARNING }));
        }
    };

    return (
        <section>

            <h1 className='h1'>{type === PageType.CREATE ? 'Creazione' : 'Modifica'} Pacchetto di Livelli</h1>

            <div className='my-6'>
                <Stepper steps={steps} currentStep={currentStep} />
            </div>

            {currentStep === 0 && (
                <div className='w-full flex flex-col gap-4 p-6 bg-white rounded-2xl shadow-lg'>

                    <h3 className='h3'>Informazioni</h3>

                    <div className='form-element !grid !grid-cols-7 gap-4'>
                        <label htmlFor='nome'>Nome pacchetto</label>
                        <input type='text' id='nome' name='Name' className='col-span-6' value={formData.Name} onChange={handleChange} />
                    </div>

                    <div className='form-element !grid !grid-cols-7 gap-4'>
                        <label htmlFor='description'>Descrizione</label>
                        <input type='text' id='description' name='Description' placeholder='facoltativo' className='col-span-6' value={formData.Description ?? ''} onChange={handleChange} />
                    </div>

                </div>
            )}

            {currentStep === 1 && (
                <div className='w-full flex flex-col p-6 bg-white rounded-2xl shadow-lg'>
                    <LevelsSelectionTable
                        rowsLimit={15}
                        selectedLevels={selectedLevels}
                        setSelectedLevels={setSelectedLevels}
                    />
                    <p className='mt-6'>
                        <b>N.B.</b> Puoi selezionare più livelli contemporaneamente trascinando il mouse sui livelli desiderati.
                        <br />
                        Potrai modificare i livelli associati in seguito.
                    </p>
                </div>
            )}

            {currentStep === 2 && (
                <div className='w-full flex flex-col p-6 bg-white rounded-2xl shadow-lg'>
                    <EmployeesSelectionTable
                        rowsLimit={15}
                        selectedEmployees={selectedEmployees}
                        setSelectedEmployees={setSelectedEmployees}
                    />
                    <p className='mt-6'>
                        <b>N.B.</b> Puoi selezionare più dipendenti contemporaneamente trascinando il mouse sui dipendenti desiderati.
                        <br />
                        Potrai modificare i dipendenti associati in seguito.
                    </p>
                </div>
            )
            }

            {/* Navigation buttons */}
            <div className='flex justify-between gap-4 my-6'>
                <button className='btn' disabled={currentStep === 0} onClick={handleBack}>
                    <MdChevronLeft size={18} /> Indietro
                </button>
                {currentStep < steps.length - 1 ? (
                    <button className='btn' onClick={handleNext}>
                        Avanti <MdChevronRight size={18} />
                    </button>
                ) : (
                    <button className='btn btn-primary' onClick={handleSave}>
                        Salva <MdSave size={18} />
                    </button>
                )}
            </div>

        </section >
    );
}

export default LevelGroupForm;