import React from 'react';
import { PageType } from '../types';
import LevelsTable from '../components/levels/LevelsTable';
import SingleLevel from '../components/levels/SingleLevel';
import LevelForm from '../components/levels/LevelForm';

interface IProps {
	type: PageType;
}

function Levels({ type }: IProps) {
	return (
		<section className='w-full h-full'>

			{type === PageType.ALL && <LevelsTable />}
			{type === PageType.SINGLE && <SingleLevel />}
			{type === PageType.CREATE && <LevelForm type={PageType.CREATE} />}
			{type === PageType.EDIT && <LevelForm type={PageType.EDIT} />}

		</section>
	);
}

export default Levels;
